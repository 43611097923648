import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { PrivateRoute } from './components'
import { DefaultLayout, AdminDefaultLayout } from './containers'
import { useAuth0 } from "./react-auth0-spa"
import history from "./utils/history"
import 'antd/dist/antd.css'
import 'bootstrap/dist/css/bootstrap.min.css'

const App = () => {
	const { loading } = useAuth0()
	return (
		<Router history={history}>
			<Switch>
				<PrivateRoute path='/dashboard' component={AdminDefaultLayout} />
				<Route path='' component={DefaultLayout} />
			</Switch>
		</Router>
	)
}

export default App;

import React, {useState, useEffect } from 'react'
import { Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import { API_BASE } from '../constants'
import { removeImage } from '../utils'
import { useAuth0 } from "../react-auth0-spa"
const { Dragger } = Upload

export default ({
    onChange,
    onRemove
}) => {
    const [imageDeleteMessage, changeImageDeleteMessage] = useState(null)
    const [header, changeHeader] = useState(null)
    const { getTokenSilently } = useAuth0()

    const handleImageComponentChange = (event, callback) => {
        // if image needs to be added to state
        if (event.file.response) callback(event)
    }

    useEffect(() => {
        (
            async () => {
                const authHeader = {
                    Authorization: `Bearer ${await getTokenSilently()}`
                }
                changeHeader(authHeader)
            }
        )()
    }, [])
    return (
        <>
            <Dragger
                headers={header}
                action={`${API_BASE}/paginatedarticlepages/addimage`}
                accept='.png,.jpg,.jpeg,.webp'
                name='file'
                multiple={false}
                onChange={(event) => handleImageComponentChange(event, (event) => onChange(event))}
                onRemove={() => removeImage(onRemove.imageLinkToRemove, (res, err) => {
                    if(res) changeImageDeleteMessage('Image Was Deleted')
                    if(err) changeImageDeleteMessage('There was an error. The image was not deleted.')
                    onRemove.callback()
                })}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                    Click or drag file to this area to upload
            </p>
                <p className="ant-upload-hint">
                    Once selected, the file will automatically be uploaded.
            </p>
            {console.log({imageDeleteMessage})}
                {
                    imageDeleteMessage
                        ? <p className="ant-upload-hint">
                            {
                                imageDeleteMessage
                            }
                        </p>
                        : null
                }
            </Dragger>
        </>
    )
}
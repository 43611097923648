import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Modal, Select } from 'antd'
const { Option } = Select

export default ({ onOk, modalStatus, modalError, onButtonClick, onCancel, initialValues}) => {
    const [articleFormContent, changePageFormContent] = useState({})

    useEffect(() => {
        changePageFormContent(initialValues)
    },[])
    return (
        <>
            <Button
                onClick={onButtonClick}
                shape='round'
                style={{
                    backgroundColor: 'rgba(7, 214, 112)',
                    color: 'white',
                }}
            >
                Add New Article
                </Button>
            <Modal
                title="Create Article"
                visible={modalStatus}
                onOk={() => onOk(articleFormContent)}
                okText={'Submit'}
                onCancel={onCancel}
            >
                <div
                    style={{
                        color: 'red'
                    }}
                >
                    {modalError ? modalError : null}
                </div>
                <br />
                <Form>
                    <Form.Item
                        name='title'
                        label="Title"
                        onChange={(event) => changePageFormContent({ ...articleFormContent, [event.target.id]: event.target.value })}
                        rules={[{ required: true }]}
                    >
                        <Input
                            placeholder='Article Title'
                        />
                    </Form.Item>
                    <Form.Item
                        name='type'
                        label="Article Type"
                        rules={[{ required: true }]}
                        initialValue={initialValues.type}
                    >
                        <Select
                            onChange={(value) => { changePageFormContent({ ...articleFormContent, ['type']: value }) }}
                        >
                            <Option value="regular">regular</Option>
                            <Option value="quiz">quiz</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='active'
                        label="Active Status"
                        rules={[{ required: true }]}
                        initialValue={initialValues.active}
                    >
                        <Select
                            onChange={(value) => { changePageFormContent({...articleFormContent, ['active']: value }) }}
                        >
                            <Option value={true}>True</Option>
                            <Option value={false}>False</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
import React, { useEffect, useState } from 'react'
import { API_BASE } from '../constants'
// import AdSense from 'react-adsense'
import { getParameterByName, updateQueryParams } from '../utils'
import { fetchData } from '../utils/axios'

export default ({
    navigateToNewTab
}) => {
    const [articlePages, changeArticlePages] = useState(null)
    const [stickyVisible, changeStickyVisible] = useState(true)

    const tileContainerRoot = 'tile-container'

    const getArticle = (articleId, skip, limit) => (
        fetchData(`${API_BASE}/paginatedarticlepages/?article=${articleId}&skip=${skip}&limit=${limit}`)
    )

    useEffect(() => {
        (async () => {
            // loads initial content
            changeArticlePages(await getArticle(getParameterByName('article'), getParameterByName('page') - 1, 3))
        })()

        window.onpopstate = async (e) => {
            // changes content on back button in browser
            // you do not need to subtract 1 from the parameter name, because the browser changes the search for you
            changeArticlePages(await getArticle(getParameterByName('article'), getParameterByName('page'), 3))
        }
    }, [])

    // for infinite scroll
    useEffect(() => {
        // event listener for for changing 'page' search param
        document.addEventListener('scroll', handleScrollToLoadMoreContent)
        return () => document.removeEventListener("scroll", handleScrollToLoadMoreContent)
    }, [articlePages])


    useEffect(() => {
        setInterval(() => {
            changeStickyVisible(false)

            setTimeout(() => {
                changeStickyVisible(true)
            }, 1000)
        }, 30000)
    }, [])

    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }

    // const isTop = el => {
    //     console.log(el.getBoundingClientRect().top, window.innerHeight)
    //     return el.getBoundingClientRect().top > 0
    // }
    const handleScrollToLoadMoreContent = async () => {
        // we're checking to see if we're at the bottom of the div that has the ID that includes the number of the 'page' search param
        // ONCE WE ARE AT THE BOTTOM, we change the 'page' search param to be one number higher
        // THEN we're checking to see if we're at the bottom of the number higher, because it is now the 'page' search param
        const articlePage = +getParameterByName('page')
        const articleTileElement = document.getElementById(tileContainerRoot + '-' + articlePage)

        // if (isTop(articleTileElement)
        // && articlePage !== articlePages[0].page_number) return navigateToNewTab('infinite', () => { }, updateQueryParams(window.location.search, 'page', articlePage - 1))

        // check if we're at the bottom of the div with the 'page' search param
        if (articleTileElement && isBottom(articleTileElement)) {
            const newArticlePage = articlePage + 1

            /**
             * because the search param for 'page' is now one number higher when we call navigateToNewTab below, 
             * when this if condition -- isBottom(articleTileElement) -- checks to see if we are at the bottom of the page onScroll,
             * it will be checking to see if we are at the bottom of the next page with the NEW search param of page
             * **/
            // change the 'page' search param, because we're at the bottom of the div with the number in the 'page' param
            navigateToNewTab('infinite', () => { }, updateQueryParams(window.location.search, 'page', newArticlePage))

            // we're triggering the load of new content on the second to last tile, once it's at the bottom of the tile
            /**
             * We're triggering the load of new content on the second to last tile; once the page_number for the second to last tile
             * equals the search param for the current page, WE KNOW WE'RE ON THE SECOND TO LAST PAGE, and we send the request. **/
            if (articlePages[articlePages.length - 2].page_number === articlePage) {
                const articleId = getParameterByName('article')
                const newContent = await getArticle(articleId, articlePage + 1, 3)
                changeArticlePages(oldData => [...oldData, ...newContent])
            }
        }

    }

    return (
        <div
            id={'parent-container'}
            style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <br></br>
            {
                !articlePages
                    ? null
                    : articlePages.map(articlePage => {
                        return <div
                            id={tileContainerRoot + '-' + articlePage.page_number}
                            number
                            style={{
                                width: '98%',
                                maxWidth: 800,
                                paddingBottom: 10,
                                paddingTop: 10,
                            }}
                        >
                            {
                                articlePage.first_page
                                    ? <div
                                        style={styles.title}
                                    >
                                        {articlePage.article_title}
                                    </div>
                                    : null
                            }
                            {
                                articlePage.page_title
                                    ? <div
                                        style={styles.title}
                                    >
                                        {articlePage.page_title}
                                    </div>
                                    : null
                            }
                            <div
                                style={styles.content}
                            >
                                {articlePage.lead}
                            </div>
                            {
                                articlePage.page_number !== 1 &&
                                <>
                                    <br />
                                    {/* <AdSense.Google
                                        client='ca-pub-1469563006407171'
                                        slot='8097030956'
                                    /> */}
                                    <br />
                                </>
                            }
                            <div
                                style={{
                                    backgroundSize: 'cover',
                                    // backgroundPositionX: -100,
                                    borderRadius: 10,
                                    height: '40vh',
                                    width: '100%',
                                    backgroundImage: `url(${articlePage.image_link})`,
                                }}
                            />
                            {
                                articlePage.page_number !== 1 &&
                                <>
                                    <br />
                                    {/* <AdSense.Google
                                        client='ca-pub-1469563006407171'
                                        slot='8097030956'
                                    /> */}
                                    <br />
                                </>
                            }
                            <p
                                style={styles.content}
                            >
                                {articlePage.conclusion}
                            </p>
                            {
                                articlePage.page_number !== 1 &&
                                <>
                                    <br />
                                    {/* <AdSense.Google
                                        client='ca-pub-1469563006407171'
                                        slot='8097030956'
                                    /> */}
                                    <br />
                                </>
                            }
                        </div>
                    })
            }
            {
                stickyVisible
                && <div
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        height: 50,
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        {/* <AdSense.Google
                            client='ca-pub-1469563006407171'
                            slot='8534378171'
                            style={{ 
                                width: 320,
                                height: 50, 
                            }}
                            format=''
                        /> */}
                    </div>
                </div>
            }
        </div>
    )
}

const styles = {
    content: {
        fontFamily: 'georgia',
        fontSize: 20,
    },
    button: {
        backgroundColor: 'red',
        height: 100,
        width: '98%',
        borderRadius: 20,
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 50,
        fontFamily: 'helvetica',
        cursor: 'pointer',
    },
    title: {
        fontFamily: 'helvetica',
        fontSize: 40,
    }
}
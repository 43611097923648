import { ArticleSetup, AddFrontContent, FrontContent } from './views'
//name has to match for table headers, path has to match with key in nav.js for navigation
const routes = [
    { path: '/articlesetup', component: ArticleSetup, name: 'articlesetup', displayName: 'Article Setup' },
    { path: '/frontcontent', component: FrontContent, name: 'frontcontent', displayName: 'Front Content' },
    { path: '/addfrontcontent', component: AddFrontContent, name: 'addfrontcontent', displayName: 'Add Front Content' },
    { path: '/', component: ArticleSetup, name: 'articlesetup', displayName: 'Article Setup' }
]

export const redirect = '/dashboard/articlesetup'

// export { redirect }

export default routes
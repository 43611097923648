import axios from 'axios'
import { API_BASE } from '../constants'
import { fetchData } from './axios'
import { defaultNavTab } from './navigation'

const updateQueryParams = (queryString, key, value) => {
    const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i")
    const separator = queryString.indexOf('?') !== -1 ? "&" : "?"

    if (queryString.match(re)) {
        queryString = queryString.replace(re, '$1' + key + "=" + value + '$2')
    } else {
        queryString = queryString + separator + key + "=" + value;
    }

    return queryString
}

const getParameterByName = (name) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

const removeImageFromDb = (deleteImageUrl, callback) => {
    axios
        .delete(`${API_BASE}/paginatedarticlepages/deleteimage/?url=${decodeURI(deleteImageUrl.split('https://the-site.s3.us-west-1.amazonaws.com/')[1])}`)
        .then(res => {
            if (callback) callback(res, null)
        })
        .catch(err => {
            if (callback) callback(null, err)
        })
}
// removes the image from db; it takes a callback and the image that should be removed
const removeImage = (imageLink, callback) => {
    if (imageLink) removeImageFromDb(imageLink, callback)
}

// sorts array of object
const sortObjectArray = (data, key, value) => {
    return data.reduce(function (data, header) {
        if (header[key] === value) return [header].concat(data)
        data.push(header)
        return data
    }, [])
}

const isNumeric = (str) => {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export {
    updateQueryParams,
    getParameterByName,
    removeImage,
    fetchData,
    sortObjectArray,
    defaultNavTab,
    isNumeric
}
import React, { useState, useEffect } from 'react'
import { Route, Switch, withRouter, useRouteMatch } from 'react-router-dom'
import routes from '../admin-routes.js'
import nav from '../admin-nav'
// import apiConfig from '../apiConfig'
import { OPEN_NAV_KEYS, LARGE_DEVICE_BREAKPOINT } from '../constants'
import axios from 'axios'
import { useAuth0 } from "../react-auth0-spa"
import { PageHeader } from 'antd'
import { LogoutButton, MobileDrawer, SideNav } from '../components'


const DefaultLayout = (props) => {
    const [largeDevice, changeDeviceScreen] = useState(window.matchMedia(`(min-width: ${LARGE_DEVICE_BREAKPOINT}px)`).matches)

    const {
        location,
        history
    } = props

    const { logout, getTokenSilently } = useAuth0()
    const match = useRouteMatch()


    useEffect(() => {
        addMediaListener(LARGE_DEVICE_BREAKPOINT)
    }, [])

    const addMediaListener = (deviceBreakpoint) => {
        const resizeHandler = e => changeDeviceScreen(e.matches)
        window.matchMedia(`(min-width: ${deviceBreakpoint}px)`).addListener(resizeHandler)
    }

    const navigateToNewTab = (key) => {
        const to = match.url + '/' + key

        if (location.pathname !== to) {
            history.push(to)
        }
    }

    // ads authorization to header for all admin requests
    axios.interceptors.request.use( async (config) => {
        const token = await getTokenSilently()
        config.headers.Authorization = `Bearer ${token}`

        return config
    }, (error) => {
        console.log(error)
    })

    const reactRouterRoutes = (routes) => (
        <Switch>
            {
                routes.map(
                    (route, index) => {
                        return route.component ? (
                            <Route
                                key={index}
                                path={`${match.url}${route.path}`}
                                render={props => (
                                    <route.component
                                        key={route.name}
                                        {...props}
                                        name={route.name}
                                        displayName={route.displayName}
                                    // tableHeaders={tableHeaders[route.name]}
                                    // apiConfig={apiConfig[route.name]}
                                    />
                                )
                                }
                            />
                        ) : null
                    }
                )
            }
        </Switch>
    )

    const mainView = reactRouterRoutes(routes)

    const defaultNavTab = (pathName) => {
        return [/[^/]*$/.exec(pathName)[0]]
    }

    return (
        <>
            {
                <div style={largeDevice ? styles.container : null}>
                    {
                        largeDevice
                            ? <SideNav
                                nav={nav}
                                defaultSelectedKeys={defaultNavTab(location.pathname)}
                                defaultOpenKeys={OPEN_NAV_KEYS}
                                onTabClick={navigateToNewTab}
                                large={largeDevice}
                            />
                            : <div style={styles.mobileHeader}>
                                <LogoutButton key={1} onLogout={logout} />
                                {
                                    <div style={styles.mobileDrawer} >
                                        <MobileDrawer>
                                            <SideNav
                                                nav={nav}
                                                defaultSelectedKeys={defaultNavTab(location.pathname)}
                                                defaultOpenKeys={OPEN_NAV_KEYS}
                                                onTabClick={navigateToNewTab}
                                                largeDevice={largeDevice}
                                            />
                                        </MobileDrawer>
                                    </div>
                                }
                            </div>
                    }
                    {
                        <div style={largeDevice ? styles.mainContainer : null}>
                            {
                                largeDevice
                                    ? <PageHeader
                                        extra={[
                                            <LogoutButton key={2} onLogout={logout} />
                                        ]}
                                    />
                                    : null
                            }
                            {
                                mainView
                            }
                        </div>
                    }
                </div>
            }
        </>
    )
}

export default withRouter(DefaultLayout)

const styles = {
    container: {
        display: 'flex',
        flex: '1 0 100vh',
    },
    mainContainer: {
        flex: '1 0 0',
        overflowY: 'scroll',
    },
    navbar: {
        width: '100%',
        height: '5%',
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'center',
    },
    logoutButton: {
        outline: 'none',
        color: '#8e8e93',
        backgroundColor: 'rgba(0, 0, 0, 0.0)',
    },
    navHeader: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    mobileDrawer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    mobileHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 10,
    }
}
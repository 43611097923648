import {
    RocketOutlined,
    SendOutlined,
    StarOutlined,
    DesktopOutlined,
    BulbOutlined,
    PushpinOutlined
} from '@ant-design/icons'

const items = [
    {
        key: 'articlesetup',
        title: 'Article Setup',
        icon: SendOutlined
    },
    // {
    //     key: 'frontcontent',
    //     title: 'Front Facing Content',
    //     icon: SendOutlined
    // },
    // {
    //     key: 'pathname',
    //     title: 'Path Name',
    //     icon: RocketOutlined
    // },
    // {
    //     key: 'devicecategory',
    //     title: 'Device Category',
    //     icon: DesktopOutlined
    // },
    {
        key: 'frontcontent',
        title: 'Front Content',
        icon: RocketOutlined,
        children: [
            {
                key: 'frontcontent',
                title: 'Overview'
            },
            {
                key: 'addfrontcontent',
                title: 'Add Front Content'
            }
        ]
    },
    // {
    //     key: 'ipaddress',
    //     title: 'IP Address',
    //     icon: BulbOutlined
    // },
    // {
    //     key: 'useragent',
    //     title: 'User Agent',
    //     icon: PushpinOutlined
    // }
]

export default items


//key is what is used for onclick to change tabs in 'to' for navigateToNewTab
//it compares that to the original route that is decided from routes.js 'path'
//so key in nav.js, needs to mathc for navigation
//the rest needs to match for the apiand table headers
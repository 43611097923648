import React, { useState, useEffect } from 'react'
import { Form, Input, Modal, Popconfirm } from 'antd'
import EditButton from './EditButton'
import DeleteIcon from './DeleteIcon'
import ImageUploader from './ImageUploader'
import { removeImage } from '../utils'

export default ({ page, updateArticlePage }) => {
    const [modalStatus, changeModalStatus] = useState(false)
    const [pageFormContent, changePageFormContent] = useState({})
    const [modalError, changeModalError] = useState(null)
    const [form] = Form.useForm()

    useEffect(() => {
        changePageFormContent(page)
    }, [])

    const removeImageFromState = pageFormContent => {
        const { image_link, ...newArticleObjWithoutImage } = pageFormContent
        changePageFormContent(newArticleObjWithoutImage)

    }

    return (
        <>
            <EditButton
                onClick={() => changeModalStatus(true)}
            />

            <Modal
                title="Edit Page"
                visible={modalStatus}
                onOk={() => {
                    updateArticlePage(pageFormContent, (error, res) => {
                        if (error) changeModalError('the update didn\'t work')
                        if (res) changeModalStatus(false)
                    })
                }}
                okText={'Submit'}
                onCancel={() => {
                    changeModalStatus(false)
                    changeModalError(null)
                }}
            >

                <div
                    style={{
                        color: 'red'
                    }}
                >
                    {modalError ? modalError : null}
                </div>
                <br />
                <Form form={form}>
                    <Form.Item
                        name="page_title"
                        onChange={(event) => changePageFormContent({ ...pageFormContent, [event.target.id]: event.target.value })
                        }
                        rules={[{ required: true }]}
                        initialValue={page.page_title}
                    >
                        <Input
                            placeholder='Page Title'
                        />
                    </Form.Item>
                    <Form.Item
                        name="lead"
                        onChange={(event) => changePageFormContent({ ...pageFormContent, [event.target.id]: event.target.value })
                        }
                        rules={[{ required: true }]}
                        initialValue={page.lead}
                    >
                        <Input.TextArea
                            placeholder='Lead'
                        />
                    </Form.Item>
                    <Form.Item
                        name="conclusion"
                        onChange={(event) => changePageFormContent({ ...pageFormContent, [event.target.id]: event.target.value })
                        }
                        rules={[{ required: true }]}
                        initialValue={page.conclusion}
                    >
                        <Input.TextArea
                            placeholder='Conclusion'
                        />
                    </Form.Item>
                    <Form.Item
                        name="tags"
                        onChange={(event) => changePageFormContent({ ...pageFormContent, [event.target.id]: event.target.value })
                        }
                        rules={[{ required: true }]}
                        initialValue={page.tags}
                    >
                        <Input.TextArea
                            placeholder='Tags'
                        />
                    </Form.Item>
                    <Form.Item>
                        {
                            page.image_link
                                ? <div
                                    style={{
                                        backgroundImage: `url(${page.image_link})`,
                                        backgroundSize: 'cover',
                                        width: 'auto',
                                        height: 200,
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Popconfirm
                                        title='Are you sure you want to delete the image?'
                                        onConfirm={() => {
                                            removeImage(page.image_link, (res, err) => {
                                                if(res) {
                                                    const pageWithoutImageLink = { ...pageFormContent, image_link: null }
                                                    //updates the state to mark image link as null
                                                    changePageFormContent(pageWithoutImageLink)
                                                    //sends an immediate update to the api to mark the image link as null for the page
                                                    updateArticlePage(pageWithoutImageLink)
                                                }
                                            })
                                        }}
                                        // onCancel={cancel}
                                        okText="Delete"
                                        cancelText="No"
                                    >
                                        <DeleteIcon />
                                    </Popconfirm>
                                </div>
                                : <ImageUploader
                                    onChange={(event) => changePageFormContent({ ...pageFormContent, image_link: event.file.response.path })}
                                    onRemove={{
                                        imageLinkToRemove: pageFormContent.image_link,
                                        callback: () => removeImageFromState(pageFormContent)
                                    }}
                                />
                        }
                    </Form.Item>
                    <Form.Item
                        name="image_attribution"
                        onChange={(event) => changePageFormContent({ ...pageFormContent, [event.target.id]: event.target.value })
                        }
                        rules={[{ required: true }]}
                        initialValue={page.image_attribution}
                    >
                        <Input
                            placeholder='Image Attribution'
                        />
                    </Form.Item>
                    <Form.Item
                        name="image_attribution_link"
                        onChange={(event) => changePageFormContent({ ...pageFormContent, [event.target.id]: event.target.value })
                        }
                        rules={[{ required: true }]}
                        initialValue={page.image_attribution_link}
                    >
                        <Input
                            placeholder='Image Attribution Link'
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
import React from 'react'

export default ({
    pageNumber,
    mainDisplay,
    bottomRight,
    bottomLeft,
    smallTile,
    imageLink
}) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                border: '1px solid rgba(220,220,220, .3)',
                borderRadius: 20,
            }}
        >
            <div
                id='image'
                style={{
                    height: 'auto',
                    minHeight: smallTile ? 150 : 200,
                    width: '30%',
                    backgroundImage: `url(${imageLink})`,
                    backgroundSize: 'cover',
                    display: 'flex',
                    justifyContent: bottomLeft ? 'space-between' : 'flex-end',
                    alignItems: 'center',
                    borderRadius: 20,
                }}
            >
                {
                    bottomLeft
                        ? <div
                            style={{
                                display: 'flex',
                                height: '98%',
                                justifyContent: 'center',
                                alignItems: 'flex-end'
                            }}
                        >
                            {bottomLeft}
                        </div>
                        : null
                }
                <div
                    style={{
                        display: 'flex',
                        height: '98%',
                        flexDirection: 'column',
                    }}
                >
                    {
                        pageNumber
                            ? <div
                                style={{
                                    flex: 1,
                                    justifyContent: 'center',
                                    display: 'flex',
                                }}
                            >
                                <div
                                    style={{
                                        margin: 10,
                                        height: 30,
                                        width: 30,
                                        backgroundColor: 'white',
                                        // color: 'rgb(7, 214, 112)',
                                        color: 'black',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontSize: 20,
                                        borderRadius: '100%',
                                        // boxShadow: '3px 3px 1px rgb(7, 214, 112)',
                                        // WebkitTextStroke: '.5px black',
                                    }}
                                >
                                    {pageNumber}
                                </div>
                            </div>

                            : null
                    }
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                        }}
                    >
                        {bottomRight}
                    </div>
                </div>
            </div>
            {
                mainDisplay
            }
        </div>
    )
}
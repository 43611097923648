import React from 'react'
import { Button } from 'antd'

export default ({ onLogout }) => (
    <Button
        onClick={onLogout}
        style={styles.logoutButton}
    >
        Logout
    </Button>
)

const styles = {
    logoutButton: {
        outline: 'none',
        color: '#8e8e93',
        backgroundColor: 'rgba(0, 0, 0, 0.0)',
    }
}
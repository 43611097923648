import React, { useState, useEffect } from 'react'
import { Form, Input, Modal, Popconfirm, Select } from 'antd'
import EditButton from './EditButton'
import { removeImage } from '../utils'
import DeleteIcon from './DeleteIcon'
import ImageUploader from './ImageUploader'
const { Option } = Select

export default ({
    tile,
    postUpdateTile
}) => {
    const [modalStatus, changeModalStatus] = useState(false)
    const [tileFormContent, changeTileFormContent] = useState({})
    const [modalError, changeModalError] = useState(null)
    const [form] = Form.useForm()
    const {
        title,
        tags,
        image_link,
        content,
        league,
        team,
        player,
        source_text,
        source_link,
        image_source,
        image_source_link
    } = tile

    useEffect(() => {
        changeTileFormContent(tile)
    }, [])

    const removeImageFromState = tileFormContent => {
        const { image_link, ...newArticleObjWithoutImage } = tileFormContent
        changeTileFormContent(newArticleObjWithoutImage)
    }
    return (
        <>
            <EditButton
                onClick={() => changeModalStatus(true)}
            />

            <Modal
                title="Edit Page"
                visible={modalStatus}
                onOk={() => {
                    postUpdateTile(tileFormContent, (res, error) => {
                        if (error) changeModalError('the update didn\'t work')
                        if (res) changeModalStatus(false)
                    })
                }}
                okText={'Submit'}
                width='80vw'
                onCancel={() => {
                    changeModalStatus(false)
                    changeModalError(null)
                }}
            >
                <div
                    style={{
                        color: 'red'
                    }}
                >
                    {modalError ? modalError : null}
                </div>
                <Form
                    form={form}
                >
                    <Form.Item
                        name='title'
                        label="Title"
                        onChange={(event) => changeTileFormContent({ ...tileFormContent, [event.target.id]: event.target.value })}
                        rules={[{ required: true }]}
                        initialValue={title}
                    >
                        <Input
                            placeholder='Title'
                        />
                    </Form.Item>
                    <span>Tags must not contain spaces and be seperated by a comma between each tag. E.g. 'nba,mlb'</span>
                    <Form.Item
                        name='tags'
                        label="Tags"
                        onChange={(event) => changeTileFormContent({ ...tileFormContent, [event.target.id]: event.target.value.split(',') })}
                        rules={[{ required: true }]}
                        initialValue={tags}
                    >
                        <Input
                            placeholder='Tags'
                        />
                    </Form.Item>
                    {console.log(image_link)}
                    <Form.Item>
                        {
                            image_link
                                ? <div
                                    style={{
                                        backgroundImage: `url(${image_link})`,
                                        backgroundSize: 'cover',
                                        width: 'auto',
                                        height: 200,
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Popconfirm
                                        title='Are you sure you want to delete the image?'
                                        onConfirm={() => {
                                            removeImage(image_link, (res, err) => {
                                                if(res) {
                                                    const pageWithoutImageLink = { ...tileFormContent, image_link: null }
                                                    //updates the state to mark image link as null
                                                    changeTileFormContent(pageWithoutImageLink)
                                                    //sends an immediate update to the api to mark the image link as null for the page
                                                    postUpdateTile(pageWithoutImageLink)
                                                }
                                            })
                                        }}
                                        // onCancel={cancel}
                                        okText="Delete"
                                        cancelText="No"
                                    >
                                        <DeleteIcon />
                                    </Popconfirm>
                                </div>
                                : <ImageUploader
                                    onChange={(event) => changeTileFormContent({ ...tileFormContent, image_link: event.file.response.path })}
                                    onRemove={{
                                        imageLinkToRemove: tileFormContent.image_link,
                                        callback: () => removeImageFromState(tileFormContent)
                                    }}
                                />
                        }
                    </Form.Item>
                    <Form.Item
                        name='content'
                        label="Content"
                        onChange={(event) => changeTileFormContent({ ...tileFormContent, [event.target.id]: event.target.value })}
                        rules={[{ required: true }]}
                        initialValue={content}
                    >
                        <Input.TextArea
                            placeholder='Content'
                        />
                    </Form.Item>
                    <Form.Item
                        name='league'
                        label="League"
                        rules={[{ required: true }]}
                        initialValue={league}
                    >
                        <Select
                            onChange={(value) => { changeTileFormContent({ ...tileFormContent, league: value }) }}
                        >
                            <Option value='nfl'>NFL</Option>
                            <Option value='mlb'>MLB</Option>
                            <Option value='nba'>NBA</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='team'
                        label="Team"
                        onChange={(event) => changeTileFormContent({ ...tileFormContent, [event.target.id]: event.target.value })}
                        rules={[{ required: true }]}
                        initialValue={team}
                    >
                        <Input
                            placeholder='Team'
                        />
                    </Form.Item>
                    <Form.Item
                        name='player'
                        label="Player"
                        onChange={(event) => changeTileFormContent({ ...tileFormContent, [event.target.id]: event.target.value })}
                        rules={[{ required: true }]}
                        initialValue={player}
                    >
                        <Input
                            placeholder='Player'
                        />
                    </Form.Item>
                    <Form.Item
                        name='source_text'
                        label="Source Text"
                        onChange={(event) => changeTileFormContent({ ...tileFormContent, [event.target.id]: event.target.value })}
                        rules={[{ required: true }]}
                        initialValue={source_text}
                    >
                        <Input
                            placeholder='Source Text'
                        />
                    </Form.Item>
                    <Form.Item
                        name='source_link'
                        label="Source Link"
                        onChange={(event) => changeTileFormContent({ ...tileFormContent, [event.target.id]: event.target.value })}
                        rules={[{ required: true }]}
                        initialValue={source_link}
                    >
                        <Input
                            placeholder='Source Link'
                        />
                    </Form.Item>
                    <Form.Item
                        name='image_source'
                        label="Image Source Text"
                        onChange={(event) => changeTileFormContent({ ...tileFormContent, [event.target.id]: event.target.value })}
                        rules={[{ required: true }]}
                        initialValue={image_source}
                    >
                        <Input
                            placeholder='Image Source Text'
                        />
                    </Form.Item>
                    <Form.Item
                        name='image_source_link'
                        label="Image Source Link"
                        onChange={(event) => changeTileFormContent({ ...tileFormContent, [event.target.id]: event.target.value })}
                        rules={[{ required: true }]}
                        initialValue={image_source_link}
                    >
                        <Input
                            placeholder='Image Source Link'
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
import React, { useState } from 'react'
import { Button, Form, Input, Modal, Upload, Select } from 'antd'
import ImageUploader from './ImageUploader'
import axios from 'axios'
import { API_BASE } from '../constants'
const { Option } = Select

export default () => {
    const [pageFormContent, changePageFormContent] = useState({})
    const [createTileStatus, changeCreateTileStatus] = useState(null)
    const [form] = Form.useForm()

    const removeImageFromState = pageFormContent => {
        const { image_link, ...newArticleObjWithoutImage } = pageFormContent
        changePageFormContent(newArticleObjWithoutImage)
    }

    const postNewFrontTile = (frontTile, callback = () => {}) => {
        axios
            .post(`${API_BASE}/articlepages/add`, frontTile)
            .then(res => {
                callback(res.data, null)
            })
            .catch(err => callback(null, err))
    }

    return (
        <>
            {console.log(pageFormContent)}
            <Form form={form}>
                {createTileStatus ? createTileStatus : null}
                <Form.Item
                    name='title'
                    onChange={(event) => changePageFormContent({ ...pageFormContent, [event.target.id]: event.target.value })}
                    rules={[{ required: true }]}
                >
                    <Input
                        placeholder='Title'
                    />
                </Form.Item>
                <Form.Item
                    name='tags'
                    onChange={(event) => changePageFormContent({ ...pageFormContent, [event.target.id]: event.target.value.split(',') })}
                    rules={[{ required: true }]}
                >
                    <Input
                        placeholder='Tags'
                    />
                </Form.Item>
                <Form.Item>
                    <ImageUploader
                        onChange={(event) => changePageFormContent({ ...pageFormContent, image_link: event.file.response.path })}
                        onRemove={{
                            imageLinkToRemove: pageFormContent.image_link,
                            callback: () => removeImageFromState(pageFormContent)
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name='content'
                    onChange={(event) => changePageFormContent({ ...pageFormContent, [event.target.id]: event.target.value })}
                    rules={[{ required: true }]}
                >
                    <Input.TextArea
                        rows={6}
                        placeholder='Content'
                    />
                </Form.Item>
                <Form.Item
                    name='league'
                    rules={[{ required: true }]}
                >
                    <Select
                        placeholder='League'
                        onChange={(value) => { changePageFormContent({ ...pageFormContent, league: value }) }}
                    >
                        <Option value='nfl'>NFL</Option>
                        <Option value='mlb'>MLB</Option>
                        <Option value='nba'>NBA</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name='team'
                    onChange={(event) => changePageFormContent({ ...pageFormContent, [event.target.id]: event.target.value })}
                    rules={[{ required: true }]}
                >
                    <Input
                        placeholder='Team'
                    />
                </Form.Item>
                <Form.Item
                    name='player'
                    onChange={(event) => changePageFormContent({ ...pageFormContent, [event.target.id]: event.target.value })}
                    rules={[{ required: true }]}
                >
                    <Input
                        placeholder='Player'
                    />
                </Form.Item>
                <Form.Item
                    name='source_text'
                    onChange={(event) => changePageFormContent({ ...pageFormContent, [event.target.id]: event.target.value })}
                    rules={[{ required: true }]}
                >
                    <Input
                        placeholder='Source Text'
                    />
                </Form.Item>
                <Form.Item
                    name='source_link'
                    onChange={(event) => changePageFormContent({ ...pageFormContent, [event.target.id]: event.target.value })}
                    rules={[{ required: true }]}
                >
                    <Input
                        placeholder='Source Link'
                    />
                </Form.Item>
                <Form.Item
                    name='image_source'
                    onChange={(event) => changePageFormContent({ ...pageFormContent, [event.target.id]: event.target.value })}
                    rules={[{ required: true }]}
                >
                    <Input
                        placeholder='Image Source Text'
                    />
                </Form.Item>
                <Form.Item
                    name='image_source_link'
                    onChange={(event) => changePageFormContent({ ...pageFormContent, [event.target.id]: event.target.value })}
                    rules={[{ required: true }]}
                >
                    <Input
                        placeholder='Image Source Link'
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        onClick={() => postNewFrontTile(pageFormContent, (res, err) => {
                            if(res) {
                                console.log('worked')
                                changePageFormContent({})
                                form.resetFields()
                                changeCreateTileStatus('Creating the article was a success!')
                            }
                            if(err) {
                                console.log('didn\'t work')
                                changeCreateTileStatus('Creating the article didn\'t work :(')
                            }
                        })}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
import React from 'react'
import { Menu } from 'antd'
import { withRouter } from 'react-router-dom'
import Logo from './Logo'
// import Navbar from 'react-bootstrap/Navbar'
// import Nav from 'react-bootstrap/Navbar'
// import NavDropdown from 'react-bootstrap/NavDropdown'

const { SubMenu } = Menu

const TopNav = ({
    nav,
    defaultSelectedKeys,
    defaultOpenKeys,
    onTabClick,
    largeDevice,
    mode,
    logo
}) => {

    const mapMenuItems = (menuItems) => (
        menuItems.map(item => (
            !item.children ?
                <Menu.Item
                    key={item.key}
                    onClick={() => onTabClick(item.key)}
                >
                    <span>{item.title}</span>
                </Menu.Item>
                : (
                    <SubMenu
                        key={item.key}
                        title={
                            <span>
                                <span>{item.title}</span>
                            </span>
                        }
                    >
                        {
                            mapMenuItems(item.children)
                        }
                    </SubMenu>
                )
        ))
    )

    const menuNavigation = mapMenuItems(nav)

    return (
        <div>
            <Menu
                mode={mode}
                defaultOpenKeys={defaultOpenKeys}
                defaultSelectedKeys={defaultSelectedKeys}

            >
                {
                    logo
                    && <Menu.Item>
                        <Logo
                            size={25}
                            onClick= {() => onTabClick('')}
                        />
                    </Menu.Item>
                }
                {
                    menuNavigation
                }
            </Menu>
        </div>
    )
}

export default withRouter(TopNav)
import React, { useState } from 'react'
import { Popconfirm } from 'antd'
import DeleteIcon from './DeleteIcon'

export default ({ onConfirm }) => {
    const [modalStatus, changeModalStatus] = useState(false)
    return (
        <>

            <Popconfirm
                title='Are you sure you want to delete this page?'
                onConfirm={() => onConfirm()}
                // onCancel={cancel}
                okText="Delete"
                cancelText="No"
            >
                <DeleteIcon
                    onClick={() => changeModalStatus(true)}
                />
            </Popconfirm>
        </>
    )
}
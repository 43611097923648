import React from 'react'
import { DeleteOutlined } from '@ant-design/icons'

export default ({ onClick }) => (
    <div
        onClick={onClick}
        style={{
            backgroundColor: 'rgb(155, 27, 0, .8)',
            color: 'white',
            height: 30,
            width: 30,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 10
        }}
    >
        <DeleteOutlined
            style={{
                fontSize: 20
            }}
        />
    </div>
)
import React, { useState, useEffect } from 'react'
import {
    DragAndDropTile,
    ModifyFrontPageTile,
    DeleteArticlePage
} from '../../components'
import axios from 'axios'
import { API_BASE } from '../../constants'


export default () => {
    const [articleTiles, changeArticleTiles] = useState([])

    useEffect(() => {
        getFrontPageTiles()
    }, [])

    const getFrontPageTiles = () => (
        axios
            .get(`${API_BASE}/articlepages/`)
            .then((res) => changeArticleTiles(res.data))
            .catch(console.log)
    )

    const postUpdateTile = (updateTileId, callback = () => {}) => {
        axios({
            method: 'post',
            url: `${API_BASE}/articlepages/update/${updateTileId._id}`,
            data: updateTileId,
            // headers: header
        })
            .then(res => {
                changeArticleTiles(articleTiles.map(obj => [res.data].find(o => o._id === obj._id) || obj))
                callback(res.data, null)
            })
            .catch(err => callback(null, err))
    }

    const deleteArticlePage = (tilePageToDeleteId, callback = () => {}  ) => {
        axios({
            method: 'delete',
            url: `${API_BASE}/articlepages/delete/${tilePageToDeleteId}`,
            // headers: header
        })
            .then(res => callback(res, null))
            .then(err => callback(null, err))
    }

    return (
        <>
            {
                articleTiles.map(tile => {
                    const {
                        title,
                        tags,
                        image_link,
                        content,
                        league,
                        team,
                        player,
                        source_text,
                        source_link,
                        image_source,
                        image_source_link,
                        _id
                    } = tile
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                paddingBottom: 10
                            }}
                        >
                            <div
                                style={{
                                    width: '98%'
                                }}
                            >
                                <DragAndDropTile
                                    mainDisplay={
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                width: '70%',
                                                height: 'auto',
                                                paddingLeft: 10,
                                            }}
                                        >
                                            <div>
                                                {title}
                                            </div>
                                            <div>
                                                Tags: {tags ? tags.map((tag, index) => index < (tags.length - 1) ? `${tag}, ` : tag) : 'there are no tags'}
                                            </div>
                                            <div>
                                                {content}
                                            </div>
                                            <div>
                                                League: {league}
                                            </div>
                                            <div>
                                                Team: {team}
                                            </div>
                                            <div>
                                                Player: {player}
                                            </div>
                                            <div>
                                                Source: <a href={source_link} >{source_text}</a>
                                            </div>
                                            <div>
                                                Image Source: <a href={image_source_link} >{image_source}</a>
                                            </div>
                                        </div>
                                    }
                                    imageLink={image_link}
                                    bottomRight={<ModifyFrontPageTile tile={tile} postUpdateTile={postUpdateTile} />}
                                    bottomLeft={<DeleteArticlePage onConfirm={() => deleteArticlePage(_id, () => changeArticleTiles(articleTiles.filter(tile => tile._id !== _id)))} />}
                                />
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}
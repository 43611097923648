import React, { useState, useEffect } from 'react'
import { Menu, Button } from 'antd'
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined
} from '@ant-design/icons'
const { SubMenu } = Menu

export default ({
    nav,
    defaultSelectedKeys,
    defaultOpenKeys,
    onTabClick,
    largeDevice
}) => {
    const [menuCollapsed, changeMenuCollapsed] = useState(true)

    return (
        <div
            style={{ width: 'auto' }}
        >
            <Menu
                style={
                    // largeDevice
                    // ? {...styles.sidenav, maxWidth: 220}
                    // : styles.sidenav
                    styles.sidenav
                }
                defaultSelectedKeys={defaultSelectedKeys}
                defaultOpenKeys={defaultOpenKeys}
                mode="inline"
                inlineCollapsed={menuCollapsed}
                onMouseEnter={() => changeMenuCollapsed(!menuCollapsed)}
                onMouseLeave={() => changeMenuCollapsed(!menuCollapsed)}
            >
                {
                    nav.map(
                        (item) => {
                            return (
                                !item.children ?
                                    <Menu.Item
                                        key={item.key}
                                        onClick={() => onTabClick(item.key)}
                                    >
                                        <item.icon />
                                        <span>{item.title}</span>
                                    </Menu.Item>
                                    : (
                                        <SubMenu
                                            key={item.key}
                                            title={
                                                <span>
                                                    <item.icon />
                                                    <span>{item.title}</span>
                                                </span>
                                            }
                                        >
                                            {
                                                item.children.map(
                                                    (navChild) => {
                                                        return <Menu.Item
                                                            key={navChild.key}
                                                            onClick={() => onTabClick(navChild.key)}
                                                        >
                                                            {navChild.title}
                                                        </Menu.Item>
                                                    }
                                                )
                                            }
                                        </SubMenu>
                                    )
                            )
                        }
                    )
                }
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Button
                        type="primary"
                        onClick={() => changeMenuCollapsed(!menuCollapsed)}
                        style={{ 
                            margin: 16,
                            backgroundColor: 'rgba(7, 214, 112)',
                            borderColor: 'rgba(7, 214, 112)',
                        }}
                    >
                        {
                            menuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                        }
                    </Button>
                </div>
            </Menu>
        </div>
    )
}

const styles = {
    sidenav: {
        // figure out what the 15% does
        flex: '0 0 15%',
        overflowY: 'scroll',
        height: '100vh',
    },
}
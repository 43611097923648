import React, { useEffect, useState } from 'react'
import { API_BASE } from '../constants'
import axios from 'axios'
import { getParameterByName, updateQueryParams } from '../utils'
import { fetchData } from '../utils/axios'

export default ({
    navigateToNewTab
}) => {
    const [articlePage, changeArticlePage] = useState(null)

    const getArticle = async (articleId, page) => {
        const articlePageArray = await fetchData(`${API_BASE}/paginatedarticlepages/?article=${articleId}&page=${page}&skip=0&limit=0`)
        return articlePageArray[0]
    }

    useEffect(() => {
        (async () => {
            // loads initial content
            changeArticlePage(await getArticle(getParameterByName('article'), getParameterByName('page')))
        })()

        window.onpopstate = async (e) => {
            // changes content on back button in browser
            // you do not need to subtract 1 from the parameter name, because the browser changes the search for you
            changeArticlePage(await getArticle(getParameterByName('article'), getParameterByName('page')))
        }
    }, [])

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <br></br>
            {
                !articlePage
                    ? null
                    : <div
                        style={{
                            width: '98%',
                            maxWidth: 800,
                            paddingBottom: 10,
                            paddingTop: 10,
                        }}
                    >
                        {
                            articlePage.first_page
                                ? <div
                                    style={styles.title}
                                >
                                    {articlePage.article_title}
                                </div>
                                : null
                        }
                        {
                            articlePage.page_title
                                ? <div
                                    style={styles.title}
                                >
                                    {articlePage.page_title}
                                </div>
                                : null
                        }
                        <div
                            style={styles.content}
                        >
                            {articlePage.lead}
                        </div>
                        <br />
                        <div
                            style={{
                                backgroundSize: 'cover',
                                // backgroundPositionX: -100,
                                borderRadius: 10,
                                height: '40vh',
                                width: '100%',
                                backgroundImage: `url(${articlePage.image_link})`,
                            }}
                        />
                        <br />
                        <p
                            style={styles.content}
                        >
                            {articlePage.conclusion}
                        </p>
                        <div
                            style={styles.button}
                            onClick={async () => {
                                // navigates to a new tab and updates url search
                                navigateToNewTab('gallery', () => { }, updateQueryParams(window.location.search, 'page', articlePage.page_number + 1))
                                // changes content of page
                                changeArticlePage(await getArticle(articlePage.article._id, articlePage.page_number + 1))
                                // scrolls to the top of the page
                                window.scrollTo(0, 70)
                            }}
                        >
                            NEXT PAGE
                        </div>
                    </div>
            }
        </div>
    )
}

const styles = {
    content: {
        fontFamily: 'georgia',
        fontSize: 20,
    },
    button: {
        backgroundColor: 'red',
        height: 100,
        width: '98%',
        borderRadius: 20,
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 50,
        fontFamily: 'helvetica',
        cursor: 'pointer',
    },
    title: {
        fontFamily: 'helvetica',
        fontSize: 40,
    }
}

import {
    HomeOutlined,
    SendOutlined,
    ThunderboltOutlined,
    FireOutlined
} from '@ant-design/icons'

const items = [
    // {
    //     key: 'nfl-offense-team-stats',
    //     title: 'NFL Team Stats',
    //     icon: FireOutlined,
    //     children: [
    //         {
    //             key: 'nfl-offense-team-stats',
    //             title: 'Offense Team Stats',
    //         },
    //         {
    //             key: 'nfl-defense-team-stats',
    //             title: 'Defense Team Stats',
    //         },
    //         {
    //             key: 'nfl-offense-true-intent-run',
    //             title: 'NFL Offense True Intent Run',
    //         }
    //     ]
    // },
    // {
    //     key: 'nfl-offense-player-stats',
    //     title: 'NFL Player Stats',
    //     icon: ThunderboltOutlined,
    //     children: [
    //         {
    //             key: 'nfl-passing-player',
    //             title: 'Quartbertack General Stats',
    //         },
    //         {
    //             key: 'nfl-offense-player-ayd-percentage-quarterback',
    //             title: 'Quartbertack Pass Depth Percentage',
    //         }
    //     ]
    // },


    {
        key: 'team-stats',
        title: 'Team Stats',
        icon: FireOutlined,
        children: [
            {
                key: 'offense-team-stats',
                title: 'Offense Team Stats',
                children: [
                    {
                        key: 'offense-general-stats',
                        title: 'General Offensive Stats'
                    },
                    {
                        key: 'offense-pass-percent-neutral-game',
                        title: 'Pass% on Down in Neutral Game'
                    },
                    {
                        key: 'offense-directional-rushing',
                        title: 'Directional Rushing'
                    },
                    {
                        key: 'offense-passing-location',
                        title: 'Passing Location'
                    }
                ]
            },
            {
                key: 'defense-team-stats',
                title: 'Defense Team Stats',
                children: [
                    {
                        key: 'defense-general-stats',
                        title: 'General Defensive Stats'
                    },
                    {
                        key: 'defense-pass-percent-neutral-game',
                        title: 'Pass% on Down in Neutral Game'
                    },
                    {
                        key: 'defense-directional-rushing',
                        title: 'Directional Rushing'
                    },
                    {
                        key: 'defense-passing-location',
                        title: 'Passing Location'
                    }
                ]
            }
        ]
    },
    {
        key: 'player-stats',
        title: 'Player Stats',
        children: [
            {
                key: 'passing',
                title: 'Passing',
                children: [
                    // {
                    //     key: 'player-quarterback-general-passing',
                    //     title: 'General Passing'
                    // },
                    {
                        key: 'player-quarterback-target-depth',
                        title: 'Target Depth'
                    },
                    {
                        key: 'player-quarterback-passing-location',
                        title: 'Passing Location'
                    }
                ]
            },
            {
                key: 'receiving',
                title: 'Receiving',
                children: [
                    // {
                    //     key: 'player-receiving-general-receiving',
                    //     title: 'General Receiving'
                    // },
                    {
                        key: 'player-receiving-target-depth',
                        title: 'Target Depth'
                    },
                    {
                        key: 'player-receiving-passing-location',
                        title: 'Receiving Location'
                    }
                ]
            },
            {
                key: 'rushing',
                title: 'Rushing',
                children: [
                    {
                        key: 'player-rushing-directional-rushing',
                        title: 'Directional Rushing'
                    }
                ]
            }
        ]
    }
]

export default items


//key is what is used for onclick to change tabs in 'to' for navigateToNewTab
//it compares that to the original route that is decided from routes.js 'path'
//so key in nav.js, needs to match for navigation
//the rest needs to match for the apiand table headers
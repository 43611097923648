import React, { useState, useEffect } from 'react'
import { LARGE_DEVICE_BREAKPOINT, API_BASE, DEFAULT_TILES_PER_PAGE } from '../constants'
import { Nav, MobileDrawer } from '../components'
import { Route, Switch, withRouter, useRouteMatch } from 'react-router-dom'
import routes from '../routes'
import nav from '../nav-bar'
import { defaultNavTab } from '../utils'
import axios from 'axios'
import { Layout, PageHeader } from 'antd'
import ReactGA from 'react-ga'
ReactGA.initialize('UA-191798209-1', {
	debug: false,
	titleCase: false,
	gaOptions: {
		userId: 123
	}
})

const { Footer } = Layout

const DefaultLayout = (props) => {
    const [largeDevice, changeDeviceScreen] = useState(window.matchMedia(`(min-width: ${LARGE_DEVICE_BREAKPOINT}px)`).matches)
    const [tileContent, changeTileContent] = useState([])
    // const [tileContent, changeTileContent] = useState(null)
    const [carouselContent, changeCarouselContent] = useState(null)
    const [drawerVisible, changeDrawerVisible] = useState(false)
    const [apiSkip, changeApiSkip] = useState(0)
    const [loadingStatus, changeLoadingStatus] = useState(false)
    const {
        location,
        history
    } = props
    const match = useRouteMatch()
    const mainDivContainerId = 'mainContainer'

    const isBottom = (el) => {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    const handleScrollToLoadMoreContent = async () => {
        const wrappedElement = document.getElementById(mainDivContainerId)
        if (isBottom(wrappedElement) && !loadingStatus) {
            changeLoadingStatus(true)
            changeTileContent([...tileContent, ...await getTileContent(defaultNavTab(location.pathname), apiSkip, (res) => {
                changeLoadingStatus(false)
                changeApiSkip(apiSkip + res.length)
            })])
        }

    }

    const countGaPageview = (path) => ReactGA.pageview(path)

    const getTileContent = async (category, skip = apiSkip, callback = () => { }) => {
        let response

        try {
            await axios.get(`${API_BASE}/articlepages/?category=${category}&limit=${DEFAULT_TILES_PER_PAGE}&skip=${skip}`)
                .then(res => {
                    response = res.data
                    callback(res.data)
                })
                .catch(console.log)
        } catch (e) {
            console.log('catch', e)
        }

        return response
    }

    /**
     * This is for infinite scroll this needs to be part of what's broken out differently or removed
     * **/
    // useEffect(() => {
    //     // event listener for scrolling to the bottom of the page
    //     document.addEventListener('scroll', handleScrollToLoadMoreContent)
    //     return () => document.removeEventListener("scroll", handleScrollToLoadMoreContent)
    // }, [apiSkip, tileContent, loadingStatus])

    useEffect(() => {
        // adds breakpoint for mobile device
        addMediaListener(LARGE_DEVICE_BREAKPOINT, changeDeviceScreen)
        // counts GA pageview
        countGaPageview(window.location.pathname + window.location.search)

        // this is specifically for the front page
        // this prevents request for tiles being made if these types of pages are being used
        // i need to find a better solution for this
        // if (
        //         !window.location.pathname.includes('article')
        //         && !window.location.pathname.includes('gallery')
        //         && !window.location.pathname.includes('nfl-offense-team-stats')
        //         && !window.location.pathname.includes('nfl-defense-team-stats')
        //     ) {
        //     // looks for the pathname for specific category to retreive specific content
        //     splitContentBetweenCarouselAndBodyAndUpdate(defaultNavTab(location.pathname))
        // }
    }, [])

    const splitContentBetweenCarouselAndBodyAndUpdate = async (key) => {
        const content = await getTileContent(key, 0, (res) => changeApiSkip(apiSkip + res.length))

        changeTileContent(content.slice(3, 12))
        changeCarouselContent(content.slice(0, 3))
    }

    const addMediaListener = (deviceBreakpoint, callback) => {
        const resizeHandler = e => callback(e.matches)
        window.matchMedia(`(min-width: ${deviceBreakpoint}px)`).addListener(resizeHandler)
    }

    const navigateToNewTab = (key, callback = () => { }, search = null) => {
        const to = match.url + key
        const { pathname } = location

        // i think that the comparison of pathname to `to` is looking to see if the page
        // that a user is going to matches the current page ....
        // in which case, the page wouldn't change
        // allows to change path if it is a gallery page
        if (pathname !== to || pathname === '/gallery' || pathname === '/gallery/infinite' || pathname === '/infinite') {
            // count pageview in GA
            countGaPageview(`${to}${search || ''}`)
            callback()
            
            history.push({
                pathname: to,
                search
            })
        }
    }

    const addQueryStringParameters = (search) => {
        history.push({
            search
        })
    }

    const reactRouterRoutes = (routes) => (
        <Switch>
            {
                routes.map(
                    (route, index) => {
                        return route.component && (
                            <Route
                                key={index}
                                path={`${match.url}${route.path}`}
                                render={props => (
                                    <route.component
                                        path={route.path}
                                        // tileContent={tileContent}
                                        // {...props}
                                        // name={route.name}
                                        navigateToNewTab={navigateToNewTab}
                                        addQueryStringParameters={addQueryStringParameters}
                                        displayName={route.displayName}
                                        config={route.config}
                                        location={location}
                                    // tableHeaders={tableHeaders[route.name]}
                                    // carouselContent={carouselContent}
                                    // tableType={route.path}
                                    />
                                )
                                }
                            />
                        )
                    }
                )
            }
        </Switch>
    )

    const mainView = reactRouterRoutes(routes)

    return (
        <div
            style={{
                minHeight: '100vh',
                // height: 50000,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}
            // id={mainDivContainerId}
        >
            <div>
                {
                    largeDevice
                        ? <Nav
                                logo
                                nav={nav}
                                defaultSelectedKeys={defaultNavTab(location.pathname)}
                                // onTabClick={(key) => navigateToNewTab(key, () => splitContentBetweenCarouselAndBodyAndUpdate(key))}
                                onTabClick={navigateToNewTab}
                                large={largeDevice}
                                mode={'horizontal'}
                            />
                        : <>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    backgroundColor: '#07D670',
                                    opacity: 0.4,
                                    padding: 10,
                                }}
                            >
                                <MobileDrawer
                                    logoClick={() => navigateToNewTab('')}
                                    drawerVisible={drawerVisible}
                                    changeDrawerVisible={changeDrawerVisible}
                                >
                                    <Nav
                                        nav={nav}
                                        defaultSelectedKeys={defaultNavTab(location.pathname)}
                                        onTabClick={
                                            (key) => {
                                                // navigateToNewTab(key, () => splitContentBetweenCarouselAndBodyAndUpdate(key))
                                                navigateToNewTab(key)
                                                changeDrawerVisible(false)
                                            }
                                        }
                                        largeDevice={largeDevice}
                                        mode={'inline'}
                                    />
                                </MobileDrawer>
                            </div>
                        </>
                }
                {
                    mainView
                }
            </div>
            <br />
            <Footer
                style={{
                    alignSelf: 'flex-end',
                    backgroundColor: '#07D670',
                    opacity: 0.4,
                    width: '100%',
                    zIndex: 999999999999
                }}
            >
                <a
                    onClick={() => navigateToNewTab('privacy-policy')}
                >
                    Privacy Policy
                </a>
            </Footer>
        </div>
    )
}

const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

export default withRouter(DefaultLayout)
import React, { useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { API_BASE } from '../../constants'
import { Select, Alert } from 'antd'
import axios from 'axios'
import {
    DragAndDropTile,
    CreateArticlePage,
    ModifyArticlePage,
    DeleteArticlePage,
    CreateNewArticle,
    DragAndDropTileMainDisplay
} from '../../components'
import { errorMonitor } from 'stream'
const { Option } = Select

const ArticleSetup = () => {
    const [articles, changeArticles] = useState(null)
    const [selectedArticle, changeSelectedArticle] = useState(null)
    const [articlePageTileContent, changeArticlePageTileContent] = useState(null)
    const [archivedArticlePageTileContent, changeArchivedArticlePageTileContent] = useState(null)
    const [modalStatusNewArticle, changeModalStatusNewArticle] = useState(false)
    const [modalError, changeModalError] = useState(null)
    const [errorMessage, changeErrorMessage] = useState(null)

    useEffect(() => {
        (async () => changeArticles(await getArticles()))()
    }, [])


    /*
        Beggining of APIS
    */
    const getArticles = () => (
        axios.get(`${API_BASE}/articles`)
            .then(res => res.data)
            .catch(console.log)
    )

    const getArticlePages = (articleId) => (
        axios.get(`${API_BASE}/paginatedarticlepages/${articleId}`)
            .then(res => res.data)
            .catch(console.log)
    )

    const updateArticlePage = (updatePage, callback) => {
        // const header = await props.getTokenObject()

        axios({
            method: 'post',
            url: `${API_BASE}/paginatedarticlepages/update/${updatePage._id}`,
            data: updatePage,
            // headers: header
        })
            .then(
                (res) => {
                    //this updates the player in the dropdown
                    changeArticlePageTileContent(articlePageTileContent.map(obj => [res.data].find(o => o._id === obj._id) || obj))
                    changeArchivedArticlePageTileContent(archivedArticlePageTileContent.map(obj => [res.data].find(o => o._id === obj._id) || obj))
                    if (callback) callback(false, true)
                }
            )
            .catch((err) => { if (callback) callback(true, false) })
    }

    const deleteArticlePage = (articlePageToDeleteId, callback) => {
        axios({
            method: 'delete',
            url: `${API_BASE}/paginatedarticlepages/delete/${articlePageToDeleteId}`,
            // data: updatePage,
            // headers: header
        })
            .then(
                (res) => {
                    callback(res)
                }
            )
            .then(console.log)
    }

    const postChangeArticleOrder = (pageId, articleId, startPage, endPage, callback = () => { }) => (
        axios
            .post(`${API_BASE}/paginatedarticlepages/bulkupdateorder/${articleId}/?start=${startPage}&end=${endPage}&updatedPage=${pageId}`)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                callback(null, err)
            })
    )

    const postChangePageStatus = (pageId, articleId, startPage, endPage, status) => (
        axios
            .post(`${API_BASE}/paginatedarticlepages/changetilestatus/${articleId}/?end=${endPage}&start=${startPage}&updatedPage=${pageId}&status=${status}`)
            .then(console.log)
            .catch(console.log)
    )

    const postNewArticle = (articleFormContent, callback) => {
        axios(
            {
                method: 'post',
                url: `${API_BASE}/articles/add`,
                data: articleFormContent,
                // headers: {
                //     'accept': 'application/json',
                //     'Accept-Language': 'en-US,en;q=0.8',
                //     'Content-Type': 'multipart/form-data;',
                // }
            }
        )
            .then((res) => {
                console.log([...articles, res.data])
                changeArticles([...articles, res.data])
                callback(false, true)
            })
            .catch((err) => callback(true, false))
    }

    /*
        End of APIS
    */

    // Function that is called when someone slects an article
    const onArticleSelect = async (e, { value }) => {
        const selectedArticle = articles.find(article => article._id === value)
        changeSelectedArticle(selectedArticle)

        const articleContent = await getArticlePages(selectedArticle._id),
            liveArticleContent = articleContent.filter(tile => tile.active === true)

        const archivedArticleContent = articleContent.filter(tile => tile.active === false)

        changeArticlePageTileContent(liveArticleContent)
        changeArchivedArticlePageTileContent(archivedArticleContent)
    }

    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    const onDragEnd = async (result, selectedArticleId) => {
        const { source, destination } = result

        // this is for the request to the api
        const startIndex = result.source.index,
            endIndex = result.destination.index

        // dropped outside the list
        if (!result.destination) {
            return;
        }

        // the first condition fires if it's within the same column
        if (source.droppableId === destination.droppableId) {
            console.log('uno')
            const updatedItems = reorder(
                getColumnContent(source.droppableId),
                source.index,
                destination.index
            );

            // change content depending on which column a tile was moved
            // if the archive column moves vertically, we don't record that in the DB
            if (source.droppableId === 'droppable2') {
                changeArchivedArticlePageTileContent(updatedItems)
            } else {
                await postChangeArticleOrder(result.draggableId, selectedArticleId, startIndex + 1, endIndex + 1, (res, err) => {
                    if (err) return changeErrorMessage(err.response.data)
                    changeArticlePageTileContent(updatedItems)
                })
            }
        } else {
            const newTileOrder = move(
                getColumnContent(source.droppableId),
                getColumnContent(destination.droppableId),
                source,
                destination
            );

            // this activates or deactivates the tile
            destination.droppableId === 'droppable2'
                ? await postChangePageStatus(result.draggableId, selectedArticleId, startIndex + 1, 0, false)
                : await postChangePageStatus(result.draggableId, selectedArticleId, startIndex + 1, endIndex + 1, true)
            changeArticlePageTileContent(newTileOrder.droppable)
            changeArchivedArticlePageTileContent(newTileOrder.droppable2)
        }
    }

    const getColumnContent = droppableId => droppableId === 'droppable2' ? archivedArticlePageTileContent : articlePageTileContent

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const grid = 8;

    // set style and active style for list
    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "#e6f7ff" : "white",
        padding: grid,
        width: '100%'
    });

    // set style and active style for item
    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
        // change background colour if dragging
        background: isDragging ? "rgba(7, 214, 112, .3)" : "white",
        backgroundOpacity: .5,
        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getDragAndDropLayoutForNewAndUnusedPages = (tileContent) => (
        <Droppable droppableId="droppable2">
            {(provided, snapshot) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                >
                    {tileContent.map((tile, index) => {
                        const {
                            image_link,
                            page_title,
                            article,
                            lead,
                            conclusion
                        } = tile

                        return (
                            <Draggable key={tile._id} draggableId={tile._id} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >
                                        <DragAndDropTile
                                            mainDisplay={
                                                <DragAndDropTileMainDisplay
                                                    articleTitle={article.article_title}
                                                    pageTitle={page_title}
                                                    lead={lead}
                                                    conclusion={conclusion}
                                                />
                                            }
                                            smallTile
                                            imageLink={image_link}
                                            bottomRight={<ModifyArticlePage page={tile} updateArticlePage={updateArticlePage} />}
                                            bottomLeft={<DeleteArticlePage onConfirm={() => deleteArticlePage(tile._id, () => changeArchivedArticlePageTileContent(archivedArticlePageTileContent.filter(page => page._id !== tile._id)))} />}
                                        />
                                    </div>
                                )}
                            </Draggable>
                        )
                    })}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    )

    // layout for drag and drop; this needs to be broken out into other compnents
    const getDragAndDropLayoutForLivePages = (tileContent) => {
        console.log(articlePageTileContent)
        return (
            // <DragDropContext onDragEnd={(result) => onDragEnd(result, articleId)}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {tileContent.map((tile, index) => {
                            const {
                                image_link,
                                page_title,
                                article,
                                lead,
                                conclusion
                            } = tile

                            return (
                                <Draggable key={tile._id} draggableId={tile._id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <DragAndDropTile
                                                mainDisplay={
                                                    <DragAndDropTileMainDisplay
                                                        articleTitle={article.article_title}
                                                        pageTitle={page_title}
                                                        lead={lead}
                                                        conclusion={conclusion}
                                                    />
                                                }
                                                imageLink={image_link}
                                                pageNumber={index + 1}
                                                bottomRight={<ModifyArticlePage page={tile} updateArticlePage={updateArticlePage} />}
                                            // users can only delete pages that are archived; to be able to delete live pages, additional work would need to be done to redo page order; not worth the effort right now
                                            // bottomLeft={<DeleteArticlePage onConfirm={() => deleteArticlePage(tile._id)} />}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            )
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            // </DragDropContext>
        )
    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <div
                style={{
                    width: '98%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: 'auto',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <Select
                        style={{
                            width: '93%',
                            height: 'auto'
                        }}
                        showSearch
                        placeholder="Select an article"
                        optionFilterProp="children"
                        onChange={onArticleSelect}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            articles
                                ? articles.map(article => <Option value={article._id}>{article.title}</Option>)
                                : null
                        }
                    </Select>
                    <div
                        style={{
                            marginLeft: 10
                        }}
                    >
                        <CreateNewArticle
                            onOk={(articleFormContent) => postNewArticle(articleFormContent, (error, res) => {
                                if (error) changeModalError('adding a new article didn\'t work')
                                if (res) changeModalStatusNewArticle(false)
                            })}
                            onCancel={() => changeModalStatusNewArticle(false)}
                            modalStatus={modalStatusNewArticle}
                            modalError={modalError}
                            onButtonClick={() => changeModalStatusNewArticle(true)}
                            initialValues={{ active: true, type: 'regular' }}
                        />
                    </div>
                </div>
                {
                    (articlePageTileContent && archivedArticlePageTileContent)
                    && <>
                        <br />
                        {
                            errorMessage
                            && <Alert
                                style={{ width: '30%' }}
                                message="Error"
                                description={errorMessage}
                                type="error"
                                closable
                                onClose={() => changeErrorMessage(false)}
                            />
                        }
                        <DragDropContext onDragEnd={(result) => onDragEnd(result, selectedArticle._id)}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <div
                                    style={{
                                        width: '30%'
                                    }}
                                >
                                    <br />
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            paddingRight: 20,
                                        }}
                                    >
                                        <CreateArticlePage
                                            selectedArticleId={selectedArticle._id}
                                            callbackOnCreate={(newArticle) => changeArchivedArticlePageTileContent([...archivedArticlePageTileContent, newArticle])}
                                        />
                                    </div>
                                    {
                                        getDragAndDropLayoutForNewAndUnusedPages(archivedArticlePageTileContent)
                                    }
                                </div>
                                <div
                                    style={{
                                        width: '70%'
                                    }}
                                >
                                    {
                                        getDragAndDropLayoutForLivePages(articlePageTileContent)
                                    }
                                </div>
                            </div>
                        </DragDropContext>
                    </>
                }
            </div>
        </div>
    )
}

export default ArticleSetup
import React, { useState, useEffect, useRef } from 'react'
import { Table, Button, Tooltip, Input } from 'antd'
import _ from 'lodash'
import {
    API_BASE,
    CHARTS_DEFAULT_START_YEAR,
    CHARTS_DEFAULT_END_YEAR,
    CHARTS_DEFAULT_GAME_TYPE,
    CHARTS_DEFAULT_START_WEEK,
    CHARTS_DEFAULT_END_WEEK,
    DATA_LAST_UPDATED,
    DATA_UPDATE_MESSAGE
} from '../../constants'
import { fetchData, sortObjectArray, getParameterByName, updateQueryParams } from '../../utils'
import { SelectButton } from '../../components'
import { InfoCircleOutlined } from '@ant-design/icons'

export default ({
    displayName,
    path,
    addQueryStringParameters,
    config,
    // countGaPageview
}) => {
    const {
        description,
        apiPath,
        defaultDown,
        defaultOffenseOrDefense,
        teamOrPlayer,
        minAttempts,
        defaultPageSize
    } = config

    const [tableData, changeTableData] = useState([])

    const [filteredTableData, changeFilteredTableData] = useState(null)
    const [searchInputValue, changeSearchInputValue] = useState('')
    const [searchTimeout, changeSearchTimeout] = useState(null)

    const [tableLoading, changeTableLoading] = useState(false)
    const [tableHeaders, changeTableHeaders] = useState([])

    const [startYearButtonOptions, changeStartYearButtonOptions] = useState([])
    const [endYearButtonOptions, changeEndYearButtonOptions] = useState([])

    const [startYearButtonValue, changeStartYearButtonValue] = useState(getParameterByName('startSeason') || CHARTS_DEFAULT_START_YEAR)
    const [endYearButtonValue, changeEndYearButtonValue] = useState(getParameterByName('endSeason') || CHARTS_DEFAULT_END_YEAR)

    const [startWeekButtonOptions, changeStartWeekButtonOptions] = useState([])
    const [endWeekButtonOptions, changeEndWeekButtonOptions] = useState([])

    const [startWeekButtonValue, changeStartWeekButtonValue] = useState(getParameterByName('startWeek') || CHARTS_DEFAULT_START_WEEK)
    const [endWeekButtonValue, changeEndWeekButtonValue] = useState(getParameterByName('endWeek') || CHARTS_DEFAULT_END_WEEK)

    const [gameTypeButtonValue, changeGameTypeButtonValue] = useState(getParameterByName('gameType') || CHARTS_DEFAULT_GAME_TYPE)

    const [snapDownButtonValue, changeSnapDownButtonValue] = useState(defaultDown ? +getParameterByName('down') || defaultDown : null)
    const [snapDownButtionOptions, changeSnapDownButtonOptions] = useState([])


    const initialMinAttempts = () => minAttempts ? window.location.search.includes('minAttempts') ? +getParameterByName('minAttempts') : minAttempts : null
    const [minAttemptsButtonValue, changeMinAttemptsButtonValue] = useState(initialMinAttempts())

    const [sortValue, changeSortValue] = useState(getParameterByName('sortValue') || null)
    const [sortOrder, changeSortOrder] = useState(getParameterByName('sortOrder') || null)


    const [pageSize, changePageSize] = useState(defaultPageSize || 32)

    const [submitButtonDisabled, changeSubmitButtonDisabled] = useState(true)

    const [componentPageUsage, changeComponentPageUsage] = useState(0)

    // handles initial page load, and runs only once
    useEffect(() => {
        initPageLoad()
    }, [])



    /**
     * Runs when componentPageUsage updates
     * This reloads page data when the forward and back button and gestures are used in the browser**/
    useEffect(() => {
        if (componentPageUsage > 0) loadTableData()
    }, [componentPageUsage])

    /**
     * This adds an event listener to onpopstate to run when forward and back button gestures are used in the browser.
     * It runs the logic to update the page by 1.) updating the search perameters and corresponding states, AND
     * reloading the table data.
     * IMPORTANT: The componentPageUsage value passed to onpopstate only contains the value for the initial render,
     * and is not updated after that. To solve for this,
     * notice this line: changeComponentPageUsage(componentPageUsage => componentPageUsage + 1)
     * This passes in the new state to be used.**/
    useEffect(() => {
        window.onpopstate = () => {
            updateSearchStatesWithCurrentSearchPath()
            changeComponentPageUsage(componentPageUsage => componentPageUsage + 1)
        }
        return () => {
            window.onpopstate = null
        }
    }, [])

    const handleSearchInput = searchValue => {
        changeSearchInputValue(searchValue)
        if (searchTimeout) clearTimeout(searchTimeout)
        if (!tableLoading) changeTableLoading(true)

        changeSearchTimeout(setTimeout(() => {
            searchValue = searchValue.toLowerCase()
            let filteredTableData

            const filterObjectByPropertyAndString = (filterData, property, filterValue) => filterData.filter(dataObject => dataObject[property].toLowerCase().includes(filterValue))

            if (teamOrPlayer) filteredTableData = filterObjectByPropertyAndString(tableData, teamOrPlayer, searchValue)
            if (defaultOffenseOrDefense) filteredTableData = filterObjectByPropertyAndString(tableData, defaultOffenseOrDefense, searchValue)

            changeFilteredTableData(filteredTableData)
            changeTableLoading(false)
        }, 1000))

    }

    const updateSearchStatesWithCurrentSearchPath = () => {
        changeStartYearButtonValue(getParameterByName('startSeason') || CHARTS_DEFAULT_START_YEAR)
        changeEndYearButtonValue(getParameterByName('endSeason') || CHARTS_DEFAULT_END_YEAR)
        changeStartWeekButtonValue(getParameterByName('startWeek') || CHARTS_DEFAULT_START_WEEK)
        changeEndWeekButtonValue(getParameterByName('endWeek') || CHARTS_DEFAULT_END_WEEK)
        changeGameTypeButtonValue(getParameterByName('gameType') || CHARTS_DEFAULT_GAME_TYPE)
        changeSnapDownButtonValue(defaultDown ? +getParameterByName('down') || defaultDown : null)
        changeMinAttemptsButtonValue(initialMinAttempts())
    }

    const initPageLoad = async () => {
        loadWeekButtonValues()
        loadYearButtonValues()
        loadTableHeaders()
        if (snapDownButtonValue) loadSnapDownButtonValues()
        loadTableData()
    }

    const loadSnapDownButtonValues = () => {
        fetchData(`${API_BASE}/nfltables/downs`, (snapDowns) => changeSnapDownButtonOptions(formatDataForSnapDownButton(snapDowns)))
    }

    const loadYearButtonValues = () => {
        // add years to dropdowns for start and end select
        fetchData(`${API_BASE}/nfltables/season-years`, (data, err) => {
            if (err) return changeEndYearButtonOptions([])
            // const yearOptions = formatYearsForSelectOptions(data)
            changeStartYearButtonOptions(formatDataForSelectButton(data))
            changeEndYearButtonOptions(formatDataForSelectButton(data))
            /**
             * the following two lines need to be uncommonted, and the two lines above deleted if
             * you want to enable the feature that disables years based on the current year values**/
            // changeStartYearButtonOptions(handleYearOptionsToDisable('start', endYearButtonValue, formatYearsForSelectOptions(data)))
            // changeEndYearButtonOptions(handleYearOptionsToDisable('end', startYearButtonValue, formatYearsForSelectOptions(data)))
        })
    }

    const loadWeekButtonValues = () => {
        // add years to dropdowns for start and end select
        fetchData(`${API_BASE}/nfltables/weeks`, (data, err) => {
            if (err) {
                changeStartWeekButtonOptions([])
                return changeEndWeekButtonOptions([])
            }

            changeStartWeekButtonOptions(formatDataForSelectButton(data))
            changeEndWeekButtonOptions(formatDataForSelectButton(data))
        })
    }

    const loadTableHeaders = () => {
        // get table headers
        fetchData(`${API_BASE}/nfltables/table-headers?type=${path}`, (data, err) => {
            if (err) return changeTableHeaders([])

            const tableHeaders = sortValue
                ? updateTableHeaders(addRenderValueToTableHeader(addSortToTableHeaders(addToolTipToTableHeaders(data))), [{ columnKey: sortValue, order: sortOrder }])
                : addRenderValueToTableHeader(addSortToTableHeaders(addToolTipToTableHeaders(data)))

            changeTableHeaders(tableHeaders)
        })
    }

    const addRenderValueToTableHeader = (tableHeaders) => {
        const clonedTableHeaders = _.cloneDeep(tableHeaders)

        return clonedTableHeaders.map(tableHeader => {
            if (tableHeader.children) addRenderValueToTableHeader(tableHeader.children)

            if (tableHeader.value_type === 'percentage') {
                tableHeader.render = (value) => (value * 100).toFixed(2) + '%'
            }
            return tableHeader
        })
    }

    const updateTableHeaders = (tableHeaders, sortObjects) => {
        return tableHeaders.map(tableHeader => {
            if (tableHeader.children) updateTableHeaders(tableHeader.children, sortObjects)

            // if there is a column that's already sorted, and a news column needs to be sorted, this deletes the sort from the initial column
            if (sortObjects.length === 2 && sortObjects[0].columnKey === tableHeader.key) {
                delete tableHeader.sortOrder
            }

            [sortObjects[sortObjects.length === 2 ? 1 : 0]].map((sortObject, index) => {
                // change tableHeader if the order property has already added
                if (sortObject.columnKey === tableHeader.key && sortObject.hasOwnProperty('order')) tableHeader.sortOrder = sortObject.order
                // remove sortOrder from tableHeader if there is no longer a sort, i.e., when sortObject.order is `undefined`
                if (sortObject.columnKey === tableHeader.key && !sortObject.order) {
                    delete tableHeader.sortOrder
                }

            })
            return tableHeader
        })
    }

    /**
     * Three operations are done in this function:
     * 1. the `tableHeaders` state is update, which controls the UI of the table
     * 2. the `sortOrder` and `sortValue` states are updated, which isn't really necessary, because they're only used when the page laods,
     * and there is already a sort in place in the search parameters to use.
     * 3. the search parameters are updated**/
    const handleSortUpdate = (sortObjects) => {
        const clonedTableHeaders = _.clone(tableHeaders)

        sortObjects = Array.isArray(sortObjects) ? sortObjects : [sortObjects]

        const newColumnToSort = sortObjects[sortObjects.length - 1]
        const {
            columnKey,
            order
        } = newColumnToSort

        // updates table header state
        changeTableHeaders(updateTableHeaders(clonedTableHeaders, sortObjects))

        // if adding a sort to a column
        if (order) {
            // change search path
            let initialSearchPath = window.location.search
            initialSearchPath = updateQueryParams(initialSearchPath, 'sortOrder', order)
            initialSearchPath = updateQueryParams(initialSearchPath, 'sortValue', columnKey)
            addQueryStringParameters(initialSearchPath)

            // addQueryStringParameters(updateQueryParams(window.location.search, 'sortOrder', order))
            // addQueryStringParameters(updateQueryParams(window.location.search, 'sortValue', columnKey))
            // change state
            changeSortOrder(order)
            changeSortValue(columnKey)
        }
        // if removing a sort to a column
        // currently, the state isn't being used for anything, but it's still being kept up to date
        if (!order) {
            // updates path
            let initialSearchPath = window.location.search
            initialSearchPath = removeURLParameter(initialSearchPath, 'sortOrder', order)
            initialSearchPath = removeURLParameter(initialSearchPath, 'sortValue', columnKey)
            addQueryStringParameters(initialSearchPath)
            // addQueryStringParameters(removeURLParameter(window.location.search, 'sortOrder'))
            // addQueryStringParameters(removeURLParameter(window.location.search, 'sortValue'))
            // updates state
            changeSortOrder(null)
            changeSortValue(null)
        }
    }


    const removeURLParameter = (url, parameter) => {
        //prefer to use l.search if you have a location/link object
        var urlparts = url.split('?');
        if (urlparts.length >= 2) {

            var prefix = encodeURIComponent(parameter) + '=';
            var pars = urlparts[1].split(/[&;]/g);

            //reverse iteration as may be destructive
            for (var i = pars.length; i-- > 0;) {
                //idiom for string.startsWith
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }

            return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        }
        return url;
    }


    const formatDataForSnapDownButton = dataOptions => {
        return dataOptions.map(dataOption => (
            {
                disabled: false,
                display: `${dataOption}${dataOption === 1 ? 'st' : dataOption === 2 ? 'nd' : dataOption === 3 ? 'rd' : 'th'} down`,
                value: dataOption
            }
        ))
    }

    const formatDataForSelectButton = dataOptions => dataOptions.map(dataOption => ({ disabled: false, display: dataOption, value: dataOption }))

    const addSortToTableHeaders = (data) => {
        data.map((header, index) => {
            if (header.children) return addSortToTableHeaders(header.children)

            data[index].sorter = {
                compare: function (a, b) {
                    return typeof a[header.key] === 'string'
                        ? a[header.key].localeCompare(b[header.key])
                        : a[header.key] - b[header.key]
                },
                multiple: data.length - index
            }
        })

        return data
    }


    const createTitleHeader = (title, tooltipText) => (

        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 4
            }}
        >
            <div
                style={{ display: 'flex', alignItems: 'center' }}
            >
                {title}
                <Tooltip
                    title={tooltipText}
                    trigger={['hover', 'click']}
                >
                    <InfoCircleOutlined style={{ fontSize: 12, marginLeft: 14, marginRight: 10 }} />
                </Tooltip>
            </div>
        </div>
    )

    const addToolTipToTableHeaders = (headers) => {
        headers.map((metric, index) => {
            if (metric.children) addToolTipToTableHeaders(metric.children)

            const { title, tooltipText } = headers[index]

            if (!metric.children) headers[index].title = (
                createTitleHeader(title, tooltipText)
            )
        })
        return headers
    }

    const loadTableData = () => {
        changeTableLoading(true)
        // disable submit button from being pressed
        changeSubmitButtonDisabled(true)

        fetchData(
            `${API_BASE}/nfltables/${apiPath}?startSeason=${startYearButtonValue}&endSeason=${endYearButtonValue}&gameType=${gameTypeButtonValue}${snapDownButtonValue ? `&down=${snapDownButtonValue}` : ''}${defaultOffenseOrDefense ? `&offenseOrDefense=${defaultOffenseOrDefense}` : ''}${teamOrPlayer ? `&teamOrPlayer=${teamOrPlayer}` : ''}${minAttemptsButtonValue ? `&minAttempts=${minAttemptsButtonValue}` : ''}${startWeekButtonValue ? `&startWeek=${startWeekButtonValue}` : ''}${endWeekButtonValue ? `&endWeek=${endWeekButtonValue}` : ''}`,
            (res, err) => {
                if (err) changeTableData([])
                if (res) changeTableData(res)
                // turn off loading of table
                changeTableLoading(false)
            }
        )

    }

    // the actually loading of new data is trigerred by the effect above
    const handleYearChange = (key, year, startOrEndYearStateChange) => {
        // updates query parameters
        // addQueryStringParameters(updateQueryParams(window.location.search, key, year))
        // changes the state
        startOrEndYearStateChange(year)
        // enable the submit button to be pressed
        changeSubmitButtonDisabled(false)
    }

    const handleWeekChange = (key, year, startOrEndWeekStateChange) => {
        // updates query parameters
        // addQueryStringParameters(updateQueryParams(window.location.search, key, year))
        // changes the state
        startOrEndWeekStateChange(year)
        // enable the submit button to be pressed
        changeSubmitButtonDisabled(false)
    }

    const handleGameTypeChange = (key, gameType) => {
        // addQueryStringParameters(updateQueryParams(window.location.search, key, gameType))
        changeGameTypeButtonValue(gameType)
        changeSubmitButtonDisabled(false)
    }

    const handleSnapDownChange = snapDown => {
        // addQueryStringParameters(updateQueryParams(window.location.search, 'down', snapDown))
        changeSnapDownButtonValue(snapDown)
        changeSubmitButtonDisabled(false)
    }

    const handleMinAttemptsChange = minAttempts => {
        // addQueryStringParameters(updateQueryParams(window.location.search, 'minAttempts', minAttempts))
        changeMinAttemptsButtonValue(minAttempts)
        changeSubmitButtonDisabled(false)
    }

    const handleYearOptionsToDisable = (type, year, valueOptions) => {

        if (type === 'start') {
            return valueOptions.map((yearOption, index) => {
                yearOption.value > year ? yearOption.disabled = true : yearOption.disabled = false
                return yearOption
            })
        }
        if (type === 'end') {
            return valueOptions.map((yearOption, index) => {
                yearOption.value < year ? yearOption.disabled = true : yearOption.disabled = false
                return yearOption
            })
        }
    }

    const handleOutOfRangeYear = (type, year) => {
        if (type === 'start' && year < startYearButtonValue) handleYearChange('startSeason', year, changeStartYearButtonValue)
        if (type === 'end' && year > endYearButtonValue) handleYearChange('endSeason', year, changeEndYearButtonValue)
    }

    const handleOutOfRangeWeek = (type, week) => {
        if (type === 'start' && week < startWeekButtonValue) handleWeekChange('startWeek', week, changeStartWeekButtonValue)
        if (type === 'end' && week > endWeekButtonValue) handleWeekChange('endWeek', week, changeEndWeekButtonValue)
    }

    const updatePageSize = def => (def !== pageSize) && changePageSize(def)

    const updateSearchParametersToMatchState = () => {
        let initialSearchPath = window.location.search
        // update search for min attempts
        if (minAttemptsButtonValue) initialSearchPath = updateQueryParams(initialSearchPath, 'minAttempts', minAttemptsButtonValue)
        // update for start year
        if (startYearButtonValue) initialSearchPath = updateQueryParams(initialSearchPath, 'startSeason', startYearButtonValue)
        if (endYearButtonValue) initialSearchPath = updateQueryParams(initialSearchPath, 'endSeason', endYearButtonValue)
        if (startWeekButtonValue) initialSearchPath = updateQueryParams(initialSearchPath, 'startWeek', startWeekButtonValue)
        if (endWeekButtonValue) initialSearchPath = updateQueryParams(initialSearchPath, 'endWeek', endWeekButtonValue)
        if (gameTypeButtonValue) initialSearchPath = updateQueryParams(initialSearchPath, 'gameType', gameTypeButtonValue)
        if (snapDownButtonValue) initialSearchPath = updateQueryParams(initialSearchPath, 'down', snapDownButtonValue)
        if (minAttemptsButtonValue) initialSearchPath = updateQueryParams(initialSearchPath, 'minAttempts', minAttemptsButtonValue)
        addQueryStringParameters(initialSearchPath)
    }

    return (
        <>
            <div
                style={{
                    margin: '10px',
                    fontSize: 12,
                    fontStyle: 'italic'
                }}
            >
                Data last updated: {DATA_LAST_UPDATED}
            </div>
            <br />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <h6
                    style={{
                        width: '95%'
                    }}
                >
                    {displayName}
                    {
                        description
                            ? <div
                                style={{ marginTop: 10 }}
                            >
                                {description}
                            </div>
                            : null
                    }
                </h6>
            </div>
            <br />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start'
                }}
            >
                {/* Season selectors */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    <div
                        style={style.buttons}
                    >
                        Start Season:{' '}
                        <SelectButton
                            options={startYearButtonOptions}
                            value={startYearButtonValue}
                            onChange={year => {
                                handleOutOfRangeYear('end', year)
                                // changeEndYearButtonOptions(handleYearOptionsToDisable('end', year, endYearButtonOptions))
                                handleYearChange('startSeason', year, changeStartYearButtonValue)
                            }}
                            width={100}
                        />
                    </div>
                    <div
                        style={style.buttons}
                    >
                        End Season:{' '}
                        <SelectButton
                            options={endYearButtonOptions}
                            value={endYearButtonValue}
                            onChange={year => {
                                handleOutOfRangeYear('start', year)
                                // changeStartYearButtonOptions(handleYearOptionsToDisable('start', year, startYearButtonOptions))
                                handleYearChange('endSeason', year, changeEndYearButtonValue)
                            }}
                            width={100}
                        />
                    </div>
                </div>

                {/* Week selectors */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: 10
                    }}
                >
                    <div
                        style={style.buttons}
                    >
                        Start Week:{' '}
                        <SelectButton
                            options={startWeekButtonOptions}
                            value={startWeekButtonValue}
                            onChange={week => {
                                handleOutOfRangeWeek('end', week)
                                // changeEndYearButtonOptions(handleYearOptionsToDisable('end', year, endYearButtonOptions))
                                handleWeekChange('startWeek', week, changeStartWeekButtonValue)
                            }}
                            width={80}
                        />
                    </div>
                    <div
                        style={style.buttons}
                    >
                        End Week:{' '}
                        <SelectButton
                            options={endWeekButtonOptions}
                            value={endWeekButtonValue}
                            onChange={week => {
                                handleOutOfRangeWeek('start', week)
                                // changeStartYearButtonOptions(handleYearOptionsToDisable('start', year, startYearButtonOptions))
                                handleWeekChange('endWeek', week, changeEndWeekButtonValue)
                            }}
                            width={80}
                        />
                    </div>
                </div>

                <div
                    style={style.buttons}
                >
                    {/* Game Type:{' '} */}
                    <SelectButton
                        options={[
                            { value: 'reg', display: 'Regular Season' },
                            { value: 'post', display: 'Post Season' },
                            { value: 'all', display: 'All Games' }
                        ]}
                        value={gameTypeButtonValue}
                        onChange={(gameType) => handleGameTypeChange('gameType', gameType)}
                        width={150}
                    />
                </div>
                {
                    snapDownButtonValue
                        ? <div
                            style={style.buttons}
                        >
                            <SelectButton
                                options={snapDownButtionOptions}
                                value={snapDownButtonValue}
                                onChange={(snapDown) => handleSnapDownChange(snapDown)}
                                width={150}
                            />
                        </div>
                        : null
                }
                {
                    minAttempts
                        ? <div
                            style={style.buttons}
                        >

                            <Input
                                // placeholder="Minimum Attempts"
                                value={minAttemptsButtonValue}
                                onChange={(event) => handleMinAttemptsChange(event.target.value)}
                                style={{ width: 250 }}
                                addonBefore="Minimum Attempts"
                            />
                        </div>
                        : null
                }
                <Button
                    style={style.buttons}
                    type="primary"
                    disabled={submitButtonDisabled}
                    onClick={() => {
                        loadTableData()
                        changeSearchInputValue('')
                        changeFilteredTableData(null)
                        updateSearchParametersToMatchState()
                        // countGaPageview(window.location.pathname+window.location.search)
                    }}
                >
                    Submit
                </Button>
            </div>
            <div
                style={style.buttons}
            >

                <Input
                    value={searchInputValue}
                    onChange={event => handleSearchInput(event.target.value)}
                    style={{ width: 250 }}
                    placeholder={`Search for ${teamOrPlayer && teamOrPlayer !== 'posteam' || teamOrPlayer && teamOrPlayer !== 'defteam' ? 'Player' : 'Team'}`}
                />
            </div>
            <br />
            <Table
                onChange={(tableObject, filters, sorter, extra) => {
                    // work on this for multisort
                    handleSortUpdate(sorter)
                    updatePageSize(tableObject.pageSize)
                }}
                bordered
                size="small"
                loading={tableLoading}
                showSorterTooltip={false}
                columns={tableHeaders}
                dataSource={filteredTableData || tableData}
                scroll={{ x: 'max-content' }}
                sortDirections={['descend', 'ascend']}
                pagination={{ pageSize: pageSize, position: ['topRight', 'bottomRight'] }}
            // sticky
            // scroll={{ y: '100vh' }}
            // scroll={{ x: 'calc(700px + 50%)' }}
            // pagination={true}
            />
        </>
    )
}

const style = {
    buttons: {
        marginLeft: 10,
        marginRight: 10,
        marginTop: 3,
        marginBottom: 3
    }
}

// interface PropsObject {
//     displayName: string,
//     path: string
// }

// export default ({
//     displayName,
//     path
// }: PropsObject) => {
//     const [tableData, changeTableData] = useState([])
//     const [tableLoading, changeTableLoading] = useState(false)
//     console.log(path)
//     useEffect(() => {
//         changeTableLoading(true);

//         (async () => changeTableData( await fetchData(`${API_BASE}/general-team-stats?offenseOrDefense=posteam&startSeason=2020&endSeason=2020`, () => {
//             changeTableLoading(false)
//         })))()
//     }, [])

//     return (
//         <>
//             <br />
//             <h6>
//                 {displayName}
//             </h6>
//             <br />
//             {/* <Table
//                 loading={tableLoading}
//                 // columns={tableHeaders}
//                 dataSource={tableData}
//                 scroll={{ x: 'max-content' }}
//                 // scroll={{ y: '100vh' }}
//                 pagination={false}
//             /> */}
//         </>
//     )
// }

import React from 'react'
import { EditOutlined } from '@ant-design/icons'

export default ({
    onClick
}) => (
    <div
        onClick={onClick}
        style={{
            backgroundColor: 'rgb(235, 156, 150, .8)',
            color: 'white',
            height: 30,
            width: 30,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 10
        }}
    >
        <EditOutlined
            style={{
                fontSize: 20
            }}
        />
    </div>
)
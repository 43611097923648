import React from 'react'
import {
    DataTableView,
    HomePage,
    GalleryPage,
    PrivacyPolicyPage,
    InfiniteScroll
} from './views'
import TestTS from './components/NewComp'

const routeConfigs = {
    teamOffense: {
        'offense-general-stats': {
            apiPath: 'general-team-stats',
            defaultOffenseOrDefense: 'posteam',
        },
        'offense-pass-percent-neutral-game': {
            apiPath: 'neutral-game-passing-percentage',
            defaultOffenseOrDefense: 'posteam',
            defaultDown: 1,
            description: 'This table shows the percent of time that an offense passed on the down selected in a neutral passing game for a specific yards to go. A neutral passing situation is defined as a play in the 1st, 2nd, or 3rd quarter, with a score differential of less than 10, and the ball between the 20 yard lines. For example, when 1st down is selected, "%9-10" is the percent of time that an offense passed on 1st down in a neutral passing situation with 9-10 yards to go.'
        },
        'offense-directional-rushing': {
            apiPath: 'directional-rushing',
            teamOrPlayer: 'posteam',
            description: 'The table below shows the percentage of time that an offense rushed to a specific location on the field. The table also shows the yards per attempt to that location.'
        },
        'offense-passing-location': {
            apiPath: 'passing-location',
            teamOrPlayer: 'posteam'
        }
    },
    teamDefense: {
        'defense-general-stats': {
            apiPath: 'general-team-stats',
            defaultOffenseOrDefense: 'defteam'
        },
        'defense-pass-percent-neutral-game': {
            apiPath: 'neutral-game-passing-percentage',
            defaultOffenseOrDefense: 'defteam',
            defaultDown: 1,
            description: 'This table shows the percent of time that an offense passed against a specific defense on the down selected in a neutral passing game for a specific yards to go. A neutral passing situation is defined as a play in the 1st, 2nd, or 3rd quarter, with a score differential of less than 10, and the ball between the 20 yard lines. For example, when 1st down is selected, "%9-10" is the percent of time that an offense passed against a specific defense on 1st down in a neutral passing situation with 9-10 yards to go.'
        },
        'defense-directional-rushing': {
            apiPath: 'directional-rushing',
            teamOrPlayer: 'defteam',
            description: 'The chart below shows the percentage of time that an offense ran against a defense to a specific location on the field. It also shows the yards per attempt to that location on the field.'
        },
        'defense-passing-location': {
            apiPath: 'passing-location',
            teamOrPlayer: 'defteam'
        }
    },
    offensivePlayer: {
        'player-rushing-directional-rushing': {
            apiPath: 'directional-rushing',
            teamOrPlayer: 'rusher_player_name',
            minAttempts: 65,
            defaultPageSize: 15
        },
        'player-quarterback-general-passing': {
            apiPath: 'general-player-passing-stats',
            minAttempts: 100,
            teamOrPlayer: 'passer_player_name',
            defaultPageSize: 15
        },
        'player-quarterback-passing-location': {
            apiPath: 'passing-location',
            teamOrPlayer: 'passer_player_name',
            minAttempts: 30
        },
        'player-receiving-passing-location': {
            apiPath: 'passing-location',
            teamOrPlayer: 'receiver_player_name',
            minAttempts: 30,
            defaultPageSize: 15,
            description: 'Targets and performance that happened at specific locations on the field.'
        },
        'player-receiving-target-depth': {
            apiPath: 'ayd-range',
            teamOrPlayer: 'receiver_player_name',
            minAttempts: 30,
            defaultPageSize: 15,
            description: 'The table below shows the percentage of targets for a reciever that came within a specific air yard range. For example, "6-10%" show the percent of targets that came between 6 and 10 air yards down field.'
        },
        'player-quarterback-target-depth': {
            apiPath: 'ayd-range',
            teamOrPlayer: 'passer_player_name',
            minAttempts: 30,
            defaultPageSize: 15,
            description: 'The table below shows the percentage of pass attempts for a quarterback that came within a specific air yard range. For example, "6-10%" show the percent of pass attempts that came between 6 and 10 air yards down field.'
        },
        'player-receiving-general-receiving': {
            apiPath: 'general-player-receiving-stats',
            minAttempts: 30,
            teamOrPlayer: 'receiver_player_name',
            defaultPageSize: 15
        }
    }
}

//name has to match for table headers, path has to match with key in nav.js for navigation
const routes = [
    { path: '/', component: HomePage, name: 'home', displayName: 'Home' },
    { path: 'privacy-policy', component: PrivacyPolicyPage },
    // offense routes
    // { path: 'offense-team-stats', component: () => 'data table page', displayName: 'Team Stats' },
    { path: 'offense-general-stats', component: DataTableView, config: routeConfigs.teamOffense['offense-general-stats'], displayName: 'Offense - General Stats' },
    { path: 'offense-pass-percent-neutral-game', component: DataTableView, config: routeConfigs.teamOffense['offense-pass-percent-neutral-game'], displayName: 'Offense - Neutral Game Passing' },
    { path: 'offense-directional-rushing', component: DataTableView, config: routeConfigs.teamOffense['offense-directional-rushing'], displayName: 'Offense - Directional Rushing' },
    { path: 'offense-passing-location', component: DataTableView, config: routeConfigs.teamOffense['offense-passing-location'],  displayName: 'Offense - Passing Location' },

    // defense routes
    // { path: 'defense-team-stats', component: DataTablePage, displayName: 'Defense Team Stats' },
    { path: 'defense-general-stats', component: DataTableView, config: routeConfigs.teamDefense['defense-general-stats'], displayName: 'Defense - General Stats' },
    { path: 'defense-pass-percent-neutral-game', component: DataTableView, config: routeConfigs.teamDefense['defense-pass-percent-neutral-game'], displayName: 'Defense - Neutral Game Passing' },
    { path: 'defense-directional-rushing', component: DataTableView, config: routeConfigs.teamDefense['defense-directional-rushing'], displayName: 'Defense - Directional Rushing' },
    { path: 'defense-passing-location', component: DataTableView, config: routeConfigs.teamDefense['defense-passing-location'] , displayName: 'Defense - Passing Location' },

    // { path: 'player-stats', component: DataTablePage, displayName: 'Player Stats' },
    // player passing
    // { path: 'passing', component: DataTablePage, displayName: 'Passing' },
    { path: 'player-quarterback-general-passing', component: DataTableView, config: routeConfigs.offensivePlayer['player-quarterback-general-passing'], displayName: 'Quarterback - General Passing' },
    { path: 'player-quarterback-target-depth', component: DataTableView, config: routeConfigs.offensivePlayer['player-quarterback-target-depth'], displayName: 'Quarterback - Target Depth' },
    { path: 'player-quarterback-passing-location', component: DataTableView, config: routeConfigs.offensivePlayer['player-quarterback-passing-location'], displayName: 'Quarterback - Passing Location' },
    // player receiving
    // { path: 'receiving', component: DataTablePage, displayName: 'Receiving' },
    { path: 'player-receiving-general-receiving', component: DataTableView, config: routeConfigs.offensivePlayer['player-receiving-general-receiving'], displayName: 'Receiving General Stats' },
    { path: 'player-receiving-target-depth', component: DataTableView, config: routeConfigs.offensivePlayer['player-receiving-target-depth'], displayName: 'Receiver - Target Depth' },
    { path: 'player-receiving-passing-location', component: DataTableView, config: routeConfigs.offensivePlayer['player-receiving-passing-location'], displayName: 'Receiver - Passing Location' },
    // player rushing
    { path: 'player-rushing-directional-rushing', component: DataTableView, config: routeConfigs.offensivePlayer['player-rushing-directional-rushing'], displayName: 'Rusher - Directional Rushing' },
    { path: 'gallery', component: GalleryPage, name: 'gallery', displayName: 'Gallery' },
    { path: 'infinite', component: InfiniteScroll, name: 'infinite', displayName: 'infinite' },
]

export default routes
const LARGE_DEVICE_BREAKPOINT = 700
const API_BASE = process.env.NODE_ENV === 'development'
    ? '/api'
    : 'https://20efxexp59.execute-api.us-west-1.amazonaws.com/prod/api'
const DEFAULT_TILES_PER_PAGE = 12
const OPEN_NAV_KEYS = []
const DATA_LAST_UPDATED = 'Monday, February 14, 2021'
const DATA_UPDATE_MESSAGE = 'Data should be updated each Monday morning following games.'


export const CHARTS_DEFAULT_START_YEAR = 2020
export const CHARTS_DEFAULT_END_YEAR = 2020
export const CHARTS_DEFAULT_START_WEEK = 1
export const CHARTS_DEFAULT_END_WEEK = 17
export const CHARTS_DEFAULT_GAME_TYPE = 'reg'

export {
    LARGE_DEVICE_BREAKPOINT,
    API_BASE,
    DEFAULT_TILES_PER_PAGE,
    OPEN_NAV_KEYS,
    DATA_LAST_UPDATED,
    DATA_UPDATE_MESSAGE
}
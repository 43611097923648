import React from 'react'
import { CreateFrontContentTile } from '../../components'

export default () => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'flex-start',
        }}
    >
        <div
            style={{
                margin: 10,
                width: '100%',
                maxWidth: 1250
            }}
        >
            <CreateFrontContentTile />
        </div>
    </div>
)
import axios from 'axios'

// export const fetchData = (endpointPath: string, callback = (res: any) => {}): any => (
//     axios.get(endpointPath)
//         .then((res: any): any => {
//             callback(res.data)
//             return res.data
//         })
//         .catch(console.log)
// )

export const fetchData = (endpointPath, callback = () => {}) => (
    axios.get(endpointPath)
        .then(res => {
            const { data } = res
            callback(data)
            return data
        })
        .catch(err => callback(null, err))
)
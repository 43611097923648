import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Modal, Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import axios from 'axios'
import { API_BASE } from '../constants'
import ImageUploader from './ImageUploader'
const { Dragger } = Upload

export default ({
    selectedArticleId,
    callbackOnCreate
}) => {
    const [modalStatus, changeModalStatus] = useState(false)
    const [pageFormContent, changePageFormContent] = useState({})
    const [form] = Form.useForm()

    const updateModalProperty = (property, updateValue, selectedPlayerModal) => {
        changePageFormContent({ ...selectedPlayerModal, [property]: updateValue })
    }

    useEffect(() => {
        changePageFormContent({
            article: selectedArticleId,
            active: false,
            page_number: 0
        })
    }, [])

    const postNewPage = (pageFormContent, callback) => {
        axios(
            {
                method: 'post',
                url: `${API_BASE}/paginatedarticlepages/add`,
                data: pageFormContent,
                // headers: {
                //     'accept': 'application/json',
                //     'Accept-Language': 'en-US,en;q=0.8',
                //     'Content-Type': 'multipart/form-data;',
                // }
            }
        )
            .then((res) => {
                callback(res.data)
            })
            .catch(console.log)
    }

    const removeImageFromState = (pageFormContent, callback) => {
        const { image_link, ...newArticleObjWithoutImage } = pageFormContent
        changePageFormContent(newArticleObjWithoutImage)
    }

    return (
        <>
            <Button
                onClick={() => changeModalStatus(true)}
                shape='round'
                style={{
                    backgroundColor: 'rgba(7, 214, 112)',
                    color: 'white',
                }}
            >
                Add New Tile
            </Button>

            <Modal
                title="Create Page"
                visible={modalStatus}
                onOk={() => postNewPage(pageFormContent, (newArticleData) => {
                    callbackOnCreate(newArticleData)
                    changeModalStatus(false)
                    form.resetFields()
                })}
                okText={'Submit'}
                onCancel={() => {
                    form.resetFields()
                    changeModalStatus(false)
                }}
            >
                <Form form={form}>
                    <Form.Item
                        name='page_title'
                        onChange={(event) => updateModalProperty(event.target.id, event.target.value, pageFormContent)}
                        rules={[{ required: true }]}
                    >
                        <Input
                            placeholder='Page Title'
                        />
                    </Form.Item>
                    <Form.Item
                        name='lead'
                        onChange={(event) => updateModalProperty(event.target.id, event.target.value, pageFormContent)}
                        rules={[{ required: true }]}
                    >
                        <Input.TextArea
                            placeholder='Lead'
                        />
                    </Form.Item>
                    <Form.Item
                        name='conclusion'
                        onChange={(event) => updateModalProperty(event.target.id, event.target.value, pageFormContent)}
                        rules={[{ required: true }]}
                    >
                        <Input.TextArea
                            placeholder='Conclusion'
                        />
                    </Form.Item>
                    <Form.Item
                        name='tags'
                        onChange={(event) => updateModalProperty(event.target.id, event.target.value, pageFormContent)}
                        rules={[{ required: true }]}
                    >
                        <Input
                            placeholder='Tags'
                        />
                    </Form.Item>
                    <Form.Item>
                        <ImageUploader
                            onChange={(event) => updateModalProperty('image_link', event.file.response.path, pageFormContent)}
                            onRemove={{
                                imageLinkToRemove: pageFormContent.image_link,
                                callback: () => removeImageFromState(pageFormContent)
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name='image_attribution'
                        onChange={(event) => updateModalProperty(event.target.id, event.target.value, pageFormContent)}
                        rules={[{ required: true }]}
                    >
                        <Input
                            placeholder='Image Attribution'
                        />
                    </Form.Item>
                    <Form.Item
                        name='image_attribution_link'
                        onChange={(event) => updateModalProperty(event.target.id, event.target.value, pageFormContent)}
                        rules={[{ required: true }]}
                    >
                        <Input
                            placeholder='Image Attribution Link'
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}


import React from 'react'

export default ({
    articleTitle,
    pageTitle,
    lead,
    conclusion
}) => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            width: '70%',
            height: 'auto',
            paddingLeft: 10,
        }}
    >
        <div>
            {articleTitle}
        </div>
        <div>
            {pageTitle}
        </div>
        <div>
            {lead}
        </div>
        <div>
            {conclusion}
        </div>
    </div>
)
import React from 'react'
import { Nav } from '../components'
import nav from '../nav-bar'
import { defaultNavTab } from '../utils'
import { Typography } from 'antd'
const { Title } = Typography

export default ({
    location,
    navigateToNewTab
}) => {
    const defaultOpenKeys = (nav) => {
        const keys = []
        const pushKeys = (nav) => {
            nav.map(item => {
                if(item.children) pushKeys(item.children)
                keys.push(item.key)
                return item.key
            })
        }
        pushKeys(nav)
        return keys
    }
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <div
                style={{
                    maxWidth: 1000,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}
            >
                {/* <div
                    style={{
                        color: '#07D670',
                        fontSize: 80
                    }}
                >
                    numbers.football
                </div> */}
                <br />
                <Title level={5} style={{marginLeft: 4}}>Site Tables</Title>
                <Nav
                    defaultOpenKeys={defaultOpenKeys(nav)}
                    nav={nav}
                    defaultSelectedKeys={defaultNavTab(location.pathname)}
                    onTabClick={key => navigateToNewTab(key)}
                    mode={'inline'}
                />
            </div>
        </div>
    )
}
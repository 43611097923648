import React from 'react'
import { Select } from 'antd'
const { Option } = Select

export default ({
    options,
    defaultValue,
    placeholder,
    value,
    onChange,
    width
}) => {
    return (
        <Select
            defaultValue={defaultValue || null}
            placeholder={placeholder  || null}
            value={value}
            onChange={value => onChange(value)}
            style={{width: width}}
        >
            {
                options.map((option, index) => <Option disabled={option.disabled} key={index} value={option.value}>{option.display}</Option>)
            }
        </Select>
    )
}


{/* <Select defaultValue="lucy" style={{ width: 120 }} disabled>
    <Option value="lucy">Lucy</Option>
</Select> */}